<template>
  <svg class="feature__icon" xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17">
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g transform="translate(-391 -1150) translate(391 1138) translate(0 12.123)">
            <path
              :fill="color"
              fill-rule="nonzero"
              d="M13.63 13.63c3.119-3.118 3.119-8.173 0-11.291C10.512-.78 5.457-.78 2.34 2.339c-3.119 3.118-3.119 8.173 0 11.291 3.118 3.119 8.176 3.119 11.291 0zM5.122 6.764L6.917 8.56l3.934-3.93 1.391 1.391-3.93 3.93-1.395 1.392L5.526 9.95 3.73 8.156l1.392-1.392z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SurfaceFeatureIcon',
  props: {
    color: {
      type: String,
      required: true
    }
  }
})
</script>
