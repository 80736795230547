<template>
  <div class="alert" :class="`alert-${color}`" role="alert">
    <div class="alert-icon">
      <em class="icon-info"></em>
    </div>
    <div class="alert-content" v-html="content"></div>
  </div>
</template>

<script>
const ALERT_COLORS = ['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark']

export default {
  name: 'AlertComponent',
  props: {
    color: {
      type: String,
      default: 'primary',
      validator: function (value) {
        return ALERT_COLORS.indexOf(value) !== -1
      }
    },
    content: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/base/_variables.scss';

.alert {
  padding: 20px;
  background-color: $white-two;
  display: flex;
  align-items: center;
  border: none;
  border-radius: 0px;
  color: $black-two;
}

.alert-icon {
  em {
    width: 20px;
    height: 20px;
    margin-right: 15px;
  }
}
</style>
