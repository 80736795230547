<template>
  <form class="roof-sides-form">
    <div v-for="(roofSide, index) in roofType.sides" :key="index" class="form-group">
      <label :for="roofSide">
        {{ t(`general.roofSides.${roofSide}`) }}
        ({{ getRoofSideLabel(roofSide) }})
        <span>({{ t('general.meter') }})</span>
      </label>
      <input
        v-model.number="validations[roofSide].$model"
        type="number"
        :name="roofSide"
        :id="roofSide"
        class="form-control"
        :class="{ 'is-invalid': validations[roofSide].$error || serverValidation[roofSide] }"
        :placeholder="0"
        data-test="roof-side-input"
        min="0"
      />
      <ErrorMessage :field="validations[roofSide]" />
    </div>
    <ServerValidation field="sides" :server-validation="serverValidation" />
  </form>
</template>

<script>
import { defineComponent, computed, reactive, toRefs } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required, minValue, maxValue, alphaNum } from '@vuelidate/validators'
import { useI18n } from 'vue-i18n'

import ServerValidation from '@/components/Global/ServerValidation'
import ErrorMessage from '@/components/Global/ErrorMessage'

export default defineComponent({
  name: 'MeasurementsFields',
  emits: ['on-input'],
  components: {
    ServerValidation,
    ErrorMessage
  },
  props: {
    roofType: {
      type: Object,
      required: true
    },
    serverValidation: {
      type: Object,
      default: () => ({})
    },
    current: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const { t } = useI18n()

    const sides = computed(() => props.current)
    const rules = computed(() => {
      const ruleSet = {}
      if (!props.roofType.sides) {
        return ruleSet
      }

      const getMaxValueForSide = side => {
        switch (side) {
          case 'side_a':
            return 60
          case 'side_b':
          case 'side_d':
          case 'side_e':
            return 30
        }
      }

      props.roofType.sides.forEach(side => {
        ruleSet[side] = {
          required,
          alphaNum,
          minValue: minValue(0),
          maxValue: maxValue(getMaxValueForSide(side))
        }
      })

      return ruleSet
    })

    const state = reactive({
      validations: null
    })

    state.validations = useVuelidate(rules.value, sides)

    function getRoofSideLabel(side) {
      return side.split('_')[1].toUpperCase()
    }

    return {
      getRoofSideLabel,
      ...toRefs(state),
      t
    }
  }
})
</script>

<style lang="scss" scoped>
@import '@/assets/style/base/_variables.scss';

.roof-sides-form {
  margin-top: 40px;

  label {
    span {
      text-transform: lowercase;
      padding: 0 5px;
    }
  }
}
</style>
