<template>
  <div class="gallery gallery-wrapper no-print" :class="{ decorated: isDecorated }">
    <div v-if="isDecorated" class="gallery-background" :style="galleryStyle"></div>
    <div class="container">
      <button
        v-if="options.showFullscreenTrigger"
        @click="toggleFullscreen"
        class="btn btn-dark gallery-fullscreen-trigger"
      >
        {{ t('gallery.buttons.action') }}
      </button>
      <Carousel
        ref="carousel"
        v-if="galleryItems.length"
        :items="galleryItems"
        :options="options"
        @on-close="toggleFullscreen"
      >
        <template v-slot:default="carouselProps">
          <div class="gallery-item">
            <img :src="carouselProps.item.image" :alt="carouselProps.item.title" @click="toggleFullscreen" />
          </div>
        </template>
        <template v-slot:fullscreen="fullscreenProps">
          <img :src="fullscreenProps.item.image" :alt="fullscreenProps.item.title" />
        </template>
        <template v-slot:fullscreen-footer="footerProps">
          <div v-if="footerProps.tile" class="gallery-tile d-lg-flex justify-content-lg-between">
            <div class="carousel-footer-block d-flex mb-3 mb-lg-0">
              <img class="gallery-tile__image" :src="footerProps.tile.image" :alt="footerProps.tile.title" />
              <div class="gallery-tile__block">
                <h4>{{ t('general.tileShape') }}</h4>
                <strong class="gallery-tile__color d-block">{{ footerProps.tile.color }}</strong>
                <span class="gallery-tile__title">{{ footerProps.tile.title }}</span>
              </div>
              <div class="gallery-tile__block align-items-center align-items-lg-start" v-if="footerProps.tile.surface">
                <h4>{{ t('general.surface') }}</h4>
                <img
                  class="gallery-tile__icon mb-2"
                  :src="footerProps.tile.surface.image"
                  :alt="footerProps.tile.surface.title"
                />
                <strong class="gallery-tile__color">{{ footerProps.tile.surface.title }}</strong>
              </div>
            </div>
            <div class="carousel-footer-button d-flex align-items-center">
              <button
                data-test="carousel-footer-button"
                @click.stop.prevent="itemHandler(footerProps.tile)"
                class="btn btn-primary ml-lg-auto"
              >
                {{ t('steps.tiles.buttons.action') }}
              </button>
            </div>
          </div>
        </template>
      </Carousel>
      <Loader class="gallery-loader" v-if="isLoading" />
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, reactive, toRefs, ref, watch } from 'vue'
import useGtmEvent from '@/hooks/gtm'
import { useI18n } from 'vue-i18n'

import useGallery from '@/hooks/gallery'
import useFormWizard from '@/hooks/formWizard'

import Loader from '@/components/Global/Loader'
import Carousel from '@/components/Carousel'

export default defineComponent({
  name: 'GalleryComponent',
  components: {
    Loader,
    Carousel
  },
  emits: ['on-select'],
  setup(props, { emit }) {
    const { galleryState, isLoading } = useGallery()
    const { currentStep } = useFormWizard()
    const { trackData } = useGtmEvent()
    const { t } = useI18n()

    const carousel = ref(null)

    const state = reactive({
      options: {
        itemsToShow: currentStep.value === 'SummaryStep' || currentStep.value === 'Tiles' ? 1 : 3,
        fullscreen: false,
        showFullscreenTrigger: currentStep.value === 'Tiles'
      }
    })

    watch(currentStep, () => {
      switch (currentStep.value) {
        case 'Tiles':
          state.options.itemsToShow = 1
          state.options.showFullscreenTrigger = true
          break
        default:
          state.options.itemsToShow = 3
          state.options.showFullscreenTrigger = false
      }
    })

    const galleryItems = computed(() => galleryState.galleryItems)
    const isDecorated = computed(() => currentStep.value === 'Summary' && galleryItems.value && carousel.value)
    const galleryStyle = computed(() => {
      if (!isDecorated.value) {
        return false
      }

      return {
        backgroundImage: `url(${galleryItems.value[carousel.value.activeItem].image})`
      }
    })

    const toggleFullscreen = key => {
      if (key > -1) {
        carousel.value.activeItem = key
      }

      state.options.fullscreen = !state.options.fullscreen
    }

    const itemHandler = item => {
      trackData({
        category: 'Cserép módosítása',
        label: `${item.title} ${item.color}`
      })

      emit('on-select', item)
      state.options.fullscreen = false
    }

    return {
      carousel,
      galleryItems,
      galleryStyle,
      isDecorated,
      isLoading,
      toggleFullscreen,
      itemHandler,
      ...toRefs(state),
      t
    }
  }
})
</script>

<style lang="scss" scoped>
@import '@/assets/style/base/_variables.scss';

.gallery-fullscreen-trigger {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
}

.gallery {
  background-image: $gradient-gray;
  position: relative;
  overflow: hidden;

  .container {
    position: relative;
  }

  &:not(.decorated) {
    height: 240px;
  }

  &.decorated {
    .gallery-item {
      width: 100%;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-image: linear-gradient(180deg, #000, transparent 30%);
        opacity: 0.6;
      }

      img {
        height: 500px;
        width: 100%;
        object-fit: cover;
      }
    }
  }

  :deep(.loader) {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.3);
  }
}

.gallery-item {
  width: 100%;

  @media screen and (max-width: 991px) {
    padding-left: 0;
    padding-right: 0;
  }

  @media screen and (min-width: 991px) {
    padding: 0 5px;
  }

  img {
    width: 100%;
    height: 240px;
    object-fit: cover;

    @media screen and (max-width: 991px) {
      height: auto;
    }
  }
}

.gallery-background {
  position: absolute;
  top: -10px;
  right: -10px;
  bottom: -10px;
  left: -10px;
  filter: blur(10px);
  background: center / cover no-repeat;
}

.gallery-tile {
  &__block {
    padding-left: 15px;
    min-width: 140px;

    @media (min-width: 991px) {
      padding: 20px 0 0 30px;
      min-width: 200px;
    }

    @media (max-width: 991px) {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }

    h4 {
      font-size: 12px;
      letter-spacing: 0;

      @media (max-width: 991px) {
        display: none;
      }
    }

    &:not(:last-child) {
      border-right: 2px solid $white;
    }
  }

  &__image {
    height: 60px;
    width: auto;

    @media (min-width: 991px) {
      height: 120px;
    }
  }

  &__color,
  &__title {
    font-size: 14px;
    letter-spacing: 0;

    @media (min-width: 991px) {
      font-size: 20px;
    }
  }

  &__color {
    font-family: $font-family-heading-extrabold;
  }

  &__title {
    text-transform: uppercase;
  }

  &__icon {
    width: 20px;
    margin-right: 5px;
  }

  .carousel-footer-button {
    @media (max-width: 991px) {
      width: 100%;
      display: block;

      .btn {
        padding: 9px;
      }
    }
  }
}

.gallery-loader {
  height: 240px;
}
</style>
