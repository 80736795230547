<template>
  <div class="form-component">
    <h2>{{ t('steps.roofType.title') }}</h2>
    <p>{{ t('steps.roofType.description') }}</p>

    <div class="roof-types-list d-flex flex-wrap justify-content-center">
      <div
        v-for="roofType in roofTypes"
        :key="roofType.id"
        @click="update(roofType)"
        class="list-item"
        :class="{ selected: isSelected(roofType) }"
      >
        <img :src="roofType.image" :alt="roofType.title" />
        <strong class="label">{{ roofType.title }}</strong>
      </div>
      <SkeletonLoader v-if="!roofTypes.length" :items="6" :items-per-row="3" disable-computed-styles />
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue'
import { useI18n } from 'vue-i18n'

import SkeletonLoader from '@/components/Global/SkeletonLoader'

export default defineComponent({
  name: 'RoofTypeSelect',
  components: {
    SkeletonLoader
  },
  emits: ['on-select'],
  props: {
    roofTypes: {
      type: Array,
      required: true
    },
    current: {
      type: [Object, Number, Boolean],
      required: true
    }
  },
  setup(props, { emit }) {
    const { t } = useI18n()

    const selectedRoofType = computed(() => props.current)

    const isSelected = ({ id }) => {
      return selectedRoofType.value.id === id
    }

    const update = roofType => {
      if (!roofType) return

      emit('on-select', roofType)
    }

    return {
      update,
      isSelected,
      t
    }
  }
})
</script>

<style lang="scss" scoped>
@import '@/assets/style/base/_variables.scss';

.roof-types-list {
  margin: 30px auto 0 auto;

  @media screen and (min-width: 992px) {
    width: 600px;
  }
}

.list-item {
  padding: 20px;
  min-height: 165px;
  height: auto;

  @media screen and (max-width: 991px) {
    width: 140px;
  }

  @media screen and (min-width: 992px) {
    width: 165px;
  }

  img {
    width: 100%;
  }
}

.form-component {
  :deep(.skeleton-loader) {
    margin-left: -15px;
    margin-right: -15px;
  }

  :deep(.skeleton) {
    width: 165px;
    height: 165px;
    margin: 15px;
    border: 2px solid #ededed;
  }
}
</style>
