<template>
  <div class="badge">
    <Tooltip :tooltip-text="badge.title" position="left">
      <img :src="badge.image" :alt="badge.title" class="badge-image" />
    </Tooltip>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

import Tooltip from '@/components/Global/Tooltip'

export default defineComponent({
  name: 'BadgeComponent',
  components: {
    Tooltip
  },
  props: {
    badge: {
      type: Object,
      required: true
    }
  }
})
</script>
