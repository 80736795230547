<template>
  <div v-if="generonData" class="generon-calculator ml-auto text-left">
    <div>
      <img class="generon-calculator__logo" src="@/assets/images/generon/generon-logo.svg" alt="generon-logo" />
      <p class="no-print">
        {{ t('banners.generon.calculator.info.title', { kwp: MAX_KWP }) }}
        <br /><br />
        {{ t('banners.generon.calculator.info.action') }}
      </p>
      <p v-if="generonData.greater_than_allowed_kwp">
        <em class="icon-info-white"></em>
        {{ t('banners.generon.calculator.max') }}
      </p>
      <h4>{{ t('banners.generon.calculator.title') }}</h4>
      <div class="ranges d-flex justify-content-between no-print">
        <span>{{ MIN_KWP }}</span>
        <span>{{ MAX_KWP }}</span>
      </div>
      <div class="generon-calculator__range no-print">
        <input
          v-model.number="kwpValue"
          @input="handleChange"
          type="range"
          id="range"
          name="generon-calculator"
          :min="MIN_KWP"
          :max="MAX_KWP"
          :step="0.5"
        />
        <div class="range-value">{{ kwpValue }}</div>
      </div>
      <div class="generon-calculator__price">
        <strong>{{ $filters.price(generonSystemPrice) }} Ft</strong>
      </div>
      <p>{{ t('banners.generon.calculator.info.price') }}</p>
      <a :href="t('banners.generon.url')" target="_blank" class="btn btn-blue no-print">
        {{ t('banners.generon.calculator.buttons.action') }}
      </a>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, toRefs } from 'vue'
import _ from 'lodash'
import { useI18n } from 'vue-i18n'

import { roundToHalf } from '@/utils/helpers'
import useFormState from '@/hooks/formState'

export default defineComponent({
  name: 'GeneronCalculator',
  setup() {
    const { formState } = useFormState()
    const { t } = useI18n()

    const generonData = formState.results.generon
    const MAX_KWP = roundToHalf(generonData?.max_kwp)
    const MIN_KWP = 2

    const state = reactive({
      kwpValue: MIN_KWP,
      generonSystemPrice: 0,
      baseTilePriceSaving: 0
    })

    function handleChange() {
      const $range = document.getElementById('range')
      const $rangeValue = document.querySelector('.range-value')
      const min = MIN_KWP

      $range.style.backgroundSize = ((state.kwpValue - min) * 100) / (MAX_KWP - min) + '% 100%'

      const newValue = Number(((state.kwpValue - min) * 100) / (MAX_KWP - min))
      const newPosition = 17.5 - newValue * 0.375 + 1.25

      $rangeValue.style.left = `calc(${newValue}% + (${newPosition}px))`

      calculateGeneronPrice()
    }

    function calculateGeneronPrice() {
      /**
       * Generon Calculation
       * -----------------------
       *
       * GF = Generon felület (m2)
       * GP = Generon nettó ár (Ft)
       * GQ = Generon mennyiség (db)
       * GT = Generon teljesítménye (kWp)
       * VAP = Választott alapcserép darabár (Ft)
       * AP = Felesleges alapcserepek ára
       *
       * Generon mennyiség (db)	GQ = GF*11,1
       * Generon teljesítménye (kWp)	GT = GQ*0,015
       * Generon nettó ár (Ft)	GP = 4500*GQ
       *
       * Generon Rendszer Ár: RENDSZER = GP + I + M + ÁE + K
       * Felesleges alapcserepek ára: AP = VAP * GQ
       *
       * Data Structure
       * -----------------
       * I = inverter_prices
       * GP = net_price
       * M = wage
       * ÁE = supplier_price
       * K = cable_prices
       * VAP = base_tile_price
       * GQ = quantity
       * GT = max_kwp
       */

      const generonQuantity = Math.ceil(state.kwpValue / generonData.base_kwp)
      const generonNetPrice = generonQuantity * generonData.base_price
      const baseTilePriceSaving = generonQuantity * generonData.base_tile_price
      const GROSS_PRICE_MULTIPLIER = 1.27

      function getPriceForRange(data, currentValue) {
        const price = _.result(
          _.find(data, range => currentValue >= range.min && (!range.max || currentValue <= range.max)),
          'price'
        )

        return price || 0
      }

      const cablePrice = getPriceForRange(generonData.cable_prices, generonQuantity)
      const inverterPrice = getPriceForRange(generonData.inverter_prices, state.kwpValue)

      const generonSystemPrice =
        (generonNetPrice + inverterPrice + generonData.wage + generonData.supplier_price + cablePrice) *
        GROSS_PRICE_MULTIPLIER

      state.generonSystemPrice = state.kwpValue > 0 ? Math.round(generonSystemPrice) : 0
      state.baseTilePriceSaving = Math.round(baseTilePriceSaving)
    }

    function init() {
      if (!generonData) {
        return
      }

      state.kwpValue = MAX_KWP
      handleChange()
      calculateGeneronPrice()
    }

    onMounted(() => {
      init()
    })

    return {
      ...toRefs(state),
      generonData,
      calculateGeneronPrice,
      handleChange,
      t,
      MAX_KWP,
      MIN_KWP
    }
  }
})
</script>

<style lang="scss" scoped>
@import '@/assets/style/base/_variables.scss';

.generon-calculator {
  padding: 30px;
  width: 100%;
  background-image: url('~@/assets/images/generon/generon-bg.jpg');
  color: $white;

  @media print {
    color: #101b31;
    background: none;
    border: 2px solid #101b31;
  }

  &__logo {
    max-width: 250px;
    margin-bottom: 30px;
  }

  .icon-info-white {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }

  h4 {
    font-size: 18px;
    font-weight: bold;
    line-height: 28px;
    margin: 20px 0 15px;
  }

  p {
    margin-top: 0;
  }

  &__price {
    font-size: 40px;
    line-height: 0.83;
    margin: 65px 0 30px;
  }

  &__range {
    position: relative;
    border-radius: 30px;
    background: #353941;
    height: 30px;
  }

  .range-value {
    color: $white;
    position: absolute;
    top: 36px;
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
  }

  .ranges {
    margin: 0 0 5px 17px;

    span {
      display: inline-block;
      font-size: 14px;
      font-weight: bold;
      line-height: 20px;
      color: #bbbbbb;
    }
  }

  input[type='range'] {
    -webkit-appearance: none;
    width: 99%;
    height: 20px;
    background: #353941;
    border-radius: 30px;
    background-image: linear-gradient($cornflower-blue, $cornflower-blue);
    background-size: 0% 100%;
    background-repeat: no-repeat;
    margin: 5px 0 0 5px;
    outline: none;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      height: 36px;
      width: 36px;
      border-radius: 100%;
      background: $white;
      cursor: ew-resize;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      transition: background 0.3s ease-in-out;
    }

    &::-moz-range-thumb {
      -webkit-appearance: none;
      height: 36px;
      width: 36px;
      border-radius: 50%;
      background: $white;
      cursor: ew-resize;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      transition: background 0.3s ease-in-out;
    }

    &::-ms-thumb {
      -webkit-appearance: none;
      height: 36px;
      width: 36px;
      border-radius: 50%;
      background: $white;
      cursor: ew-resize;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      transition: background 0.3s ease-in-out;
    }

    &::-webkit-slider-thumb:hover,
    &::-moz-range-thumb:hover,
    &::-ms-thumb:hover {
      background: $white;
    }

    &::-webkit-slider-runnable-track,
    &::-moz-range-track,
    &::-ms-track {
      -webkit-appearance: none;
      box-shadow: none;
      border: none;
      background: transparent;
    }
  }
}
</style>
