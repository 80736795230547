<template>
  <nav class="d-flex" :class="{ transparent: isLastStep && galleryItems.length }">
    <img :src="logo" :alt="t('title')" class="logo no-print" />
  </nav>
</template>

<script>
import { defineComponent, computed } from 'vue'
import { useI18n } from 'vue-i18n'

import useFormWizard from '@/hooks/formWizard'
import useGallery from '@/hooks/gallery'

export default defineComponent({
  name: 'NavBar',
  setup() {
    const { isLastStep } = useFormWizard()
    const { t } = useI18n()
    const { galleryState } = useGallery()
    const currentLanguage = localStorage.getItem('language') || process.env.VUE_APP_DEFAULT_LOCALE

    const logo = computed(() => require(`@/assets/images/logo_${currentLanguage}.svg`))
    const galleryItems = computed(() => galleryState.galleryItems)

    return {
      isLastStep,
      logo,
      t,
      galleryItems
    }
  }
})
</script>

<style lang="scss" scoped>
@import '@/assets/style/base/_variables.scss';

nav {
  background-image: $gradient-main;

  &.transparent {
    background: transparent;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 1;

    .logo {
      filter: brightness(0) invert(1);
    }
  }

  .logo {
    max-width: 130px;

    @media screen and (max-width: 991px) {
      margin: 15px auto;
    }

    @media screen and (min-width: 992px) {
      margin: 20px auto;
    }
  }
}
</style>
