<template>
  <div class="form-wizard-step">
    <div class="form-wizard-content">
      <section>
        <div class="container">
          <div class="form-wizard-heading">
            <h2 class="text-center">{{ t('steps.accessory.title') }}</h2>
            <p class="text-center mb-5">{{ t('steps.accessory.description') }}</p>
            <Alert :content="t('steps.accessory.info')" />
          </div>
          <AccessoryTable />
        </div>
      </section>
    </div>
    <div class="form-wizard-footer">
      <div class="form-wizard-actions container">
        <div class="row">
          <div class="col-md-6 order-1 order-md-0">
            <button data-test="previous-step" @click="prevStep" class="btn btn-secondary btn-block">
              {{ t('steps.accessory.buttons.prev') }}
            </button>
          </div>
          <div class="col-md-6">
            <button data-test="next-step" @click="nextStep" :disabled="isLastStep" class="btn btn-primary btn-block">
              {{ t('steps.accessory.buttons.next') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onBeforeMount, onUnmounted } from 'vue'
import { useI18n } from 'vue-i18n'

import useFormWizard from '@/hooks/formWizard'
import AccessoryTable from '@/components/Calculator/AccessoryTable.vue'
import Alert from '@/components/Global/Alert.vue'

export default defineComponent({
  components: { AccessoryTable, Alert },
  name: 'AccessoryStep',
  setup() {
    const { nextStep, prevStep, isLastStep } = useFormWizard()
    const { t } = useI18n()

    onBeforeMount(() => {
      document.addEventListener('keypress', submitHandler)
    })

    onUnmounted(() => {
      document.removeEventListener('keypress', submitHandler)
    })

    const submitHandler = e => e.key === 'Enter' && nextStep()

    return {
      nextStep,
      prevStep,
      isLastStep,
      t
    }
  }
})
</script>
