<template>
  <NavBar />
  <router-view />
  <AppFooter />
</template>

<script>
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import { useHead } from '@vueuse/head'

import NavBar from '@/components/NavBar.vue'
import AppFooter from '@/components/AppFooter.vue'

export default defineComponent({
  name: 'App',
  components: {
    NavBar,
    AppFooter
  },
  setup() {
    const { t } = useI18n()

    useHead({
      title: t('title'),
      htmlAttrs: {
      lang: localStorage.getItem('language'),
      },
      meta: [
        {
          name: 'description',
          content: t('description')
        }
      ]
    })
  }
})
</script>

<style lang="scss">
@import '@/assets/style/main.scss';
</style>
