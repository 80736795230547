<template>
  <div class="form-component">
    <h2>{{ t('steps.tiles.title') }}</h2>
    <p v-html="t('steps.tiles.description')"></p>
    <div v-if="surfaces.length" class="surfaces">
      <div class="row">
        <div v-for="surface in surfaces" :key="surface.id" class="col-lg-3 surface">
          <div class="surface__block d-sm-flex flex-sm-column justify-content-sm-between">
            <div class="surface__content">
              <div class="surface__header d-flex d-lg-block align-items-center">
                <img class="surface__icon" :src="surface.image" :alt="surface.title" />
                <h3 class="surface__title">{{ surface.title }}</h3>
              </div>

              <div class="tiles-list d-flex flex-sm-wrap">
                <div
                  v-for="tile in surface.tiles"
                  :key="tile.id"
                  @click="update(tile)"
                  class="tiles-list-item"
                  :class="{ selected: isSelected(tile) }"
                >
                  <img :src="tile.image" :alt="tile.title" class="tiles-list-item__image" />
                  <div>
                    <strong class="tiles-list-item__color">{{ tile.color }}</strong>
                    <span class="tiles-list-item__title">{{ tile.title }}</span>
                  </div>
                  <strong class="tiles-list-item__price">{{ tile.gross }} {{ t('general.currency') }}</strong>
                  <div class="badges">
                    <Badge v-for="(badge, index) in tile.badges" :key="index" :badge="badge" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div v-for="surface in surfaces" :key="surface.id" class="col-lg-3 surface">
          <ul v-if="surface.features" class="surface__features text-left d-none d-lg-block">
            <li v-for="(feature, index) in surface.features" :key="index" class="surface__feature d-flex">
              <SurfaceFeatureIcon :color="feature.color" />
              <span class="feature__label">{{ feature.label }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <SkeletonLoader v-else :items="4" :items-per-row="4" />
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue'
import useGtmEvent from '@/hooks/gtm'
import { useI18n } from 'vue-i18n'

import SkeletonLoader from '@/components/Global/SkeletonLoader.vue'
import SurfaceFeatureIcon from '@/components/Calculator/SurfaceFeatureIcon.vue'
import Badge from '@/components/Global/Badge.vue'

export default defineComponent({
  name: 'TilesSelect',
  components: {
    SkeletonLoader,
    SurfaceFeatureIcon,
    Badge
  },
  emits: ['on-select'],
  props: {
    surfaces: {
      type: Array,
      required: true
    },
    current: {
      type: [Object, Boolean],
      required: true
    }
  },
  setup(props, { emit }) {
    const selectedTile = computed(() => props.current)
    const { trackData } = useGtmEvent()
    const { t } = useI18n()

    const isSelected = ({ id }) => {
      return selectedTile.value.id === id
    }

    const update = tile => {
      if (!tile) return

      trackData({
        category: 'Cserép módosítása',
        label: `${tile.title} ${tile.color}`
      })

      emit('on-select', tile)
    }

    return {
      isSelected,
      update,
      t
    }
  }
})
</script>

<style lang="scss" scoped>
@import '@/assets/style/base/_variables.scss';

.form-component {
  :deep(.skeleton) {
    height: 600px;
    align-items: flex-start;
  }
}

h2 {
  @media (max-width: 991px) {
    margin-bottom: 5px;
    margin-top: 30px;
  }

  @media (min-width: 992px) {
    margin-bottom: 8px;
    margin-top: 60px;
  }
}

.surfaces {
  @media (max-width: 991px) {
    margin-top: 35px;
    margin-bottom: 60px;
  }

  @media (min-width: 992px) {
    margin-top: 50px;
    margin-bottom: 90px;
  }
}

.surface {
  &:not(:last-child) {
    @media (max-width: 991px) {
      .surface__content {
        border-bottom: 3px solid $white-two;
        margin-bottom: 30px;
      }
    }
  }

  &__feature {
    margin-bottom: 10px;
  }

  .feature {
    &__icon {
      min-width: 16px;
    }
  }

  &__block {
    height: 100%;
    position: relative;

    @media (min-width: 992px) {
      &::after {
        content: '';
        position: absolute;
        right: -15px;
        top: 0;
        width: 2px;
        height: 100%;
        display: block;
        background-image: linear-gradient(to bottom, $white-two 37%, $white);
      }
    }
  }

  &__title {
    font-family: $font-family-heading-extrabold;
    font-size: 20px;
    font-weight: 800;
    margin: 0 0 0 5px;

    @media (min-width: 991px) {
      font-size: 24px;
      margin: 5px 0 0;
    }
  }

  &__icon {
    max-width: 25px;

    @media (min-width: 991px) {
      max-width: 100%;
    }
  }

  &__features {
    list-style-type: none;
    margin: 0;
    padding: 0;

    .feature__label {
      font-size: 12px;
      color: $black-two;
      letter-spacing: 0;
      padding-left: 8px;
      line-height: 1.4;
    }
  }
}

.tiles-list {
  margin: 30px 0 35px;

  @media (max-width: 991px) {
    padding: 20px 0 30px;
    margin: 0 -20px 0 0;
    overflow-x: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.tiles-list-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  border: 4px solid rgba(0, 0, 0, 0);
  cursor: pointer;
  padding: 10px 5px;
  margin: 5px;
  position: relative;
  min-width: 140px;
  max-width: 140px;
  height: 220px;

  @media (min-width: 991px) {
    padding: 10px;
    flex: 0 0 calc(50% - 10px);
    min-width: auto;
    max-width: calc(50% - 10px);
  }

  .badges {
    position: absolute;
    top: 15px;
    right: 15px;
    display: flex;
    flex-direction: column;
  }

  &__color,
  &__price {
    font-family: $font-family-heading;
    font-weight: bold;
    font-size: 14px;
  }

  &__title {
    display: block;
    padding-bottom: 12px;
    font-family: $font-family-base;
    font-size: 14px;
    letter-spacing: 0;
    text-transform: uppercase;
  }

  &__image {
    width: 100%;
  }

  &__price {
    margin-top: auto;
  }

  &.selected {
    border-color: #fdab1c;
  }
}
</style>
