import { useGtm } from '@gtm-support/vue-gtm'

export default function useGtmEvent() {
  const gtm = useGtm()
  const options = {
    event: 'CustomEvent'
  }

  const trackEvent = args => {
    if (args) {
      Object.assign(options, args)
    }

    gtm.trackEvent(options)
  }

  const trackBanner = args => {
    const trackerOptions = {
      event: 'BannerClick',
      category: 'banner',
      action: 'kattintas'
    }

    if (args) {
      Object.assign(options, trackerOptions, args)
    }

    gtm.trackEvent(options)
  }

  const trackStep = args => {
    const trackerOptions = {
      event: 'StepChanged'
    }

    if (args) {
      Object.assign(options, trackerOptions, args)
    }

    gtm.trackEvent(options)
  }

  const trackData = args => {
    const trackerOptions = {
      event: 'DataChanged',
      action: 'kattintas'
    }

    if (args) {
      Object.assign(options, trackerOptions, args)
    }

    gtm.trackEvent(options)
  }

  return {
    trackEvent,
    trackBanner,
    trackStep,
    trackData
  }
}
