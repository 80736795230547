export default function useScroll() {
  const scrollToTop = () => window.scrollTo(0, 0)

  const scrollToElement = element => element.scrollIntoView({ behavior: 'smooth', block: 'start' })

  return {
    scrollToTop,
    scrollToElement
  }
}
