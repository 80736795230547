<template>
  <div class="validation-errors" v-if="!isEmpty(serverValidation) && serverValidation[field]">
    <p class="error">
      {{ serverValidation[field] }}
    </p>
  </div>
</template>
<script>
import { defineComponent } from 'vue'

import { isEmpty } from '@/utils/helpers'

export default defineComponent({
  name: 'ServerValidation',
  props: {
    field: {
      type: String,
      required: true
    },
    serverValidation: {
      type: Object,
      required: true
    }
  },
  setup() {
    return {
      isEmpty
    }
  }
})
</script>

<style lang="scss" scoped>
.validation-errors {
  .error {
    font-weight: bold;
    color: #e45043;
    margin: 5px 0 5px 5px;
  }
}
</style>
