import { createGtm } from '@gtm-support/vue-gtm'
import router from '@/router'

const gtmService = createGtm({
  id: process.env.VUE_APP_GTM_TRACKING_ID,
  defer: false,
  compatibility: false,
  debug: false,
  // enabled: process.env.NODE_ENV !== 'development',
  enabled: true,
  loadScript: true,
  vueRouter: router,
  trackOnNextTick: false
})

export default gtmService
