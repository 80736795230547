<template>
  <div class="form-wizard-step">
    <div class="form-wizard-content text-center">
      <div class="container">
        <TilesSelect :surfaces="surfaces" :current="tile" @on-select="updateState" />
      </div>
    </div>

    <div class="form-wizard-footer">
      <div class="form-wizard-actions container">
        <div class="row">
          <div class="col-md-6 order-1 order-md-0">
            <button
              data-test="previous-step"
              @click="stepBack"
              :disabled="isLoading"
              class="btn btn-secondary btn-block"
            >
              {{ t('steps.tiles.buttons.prev') }}
            </button>
          </div>
          <div class="col-md-6">
            <button
              data-test="next-step"
              @click="validateStep"
              :disabled="isLastStep || isLoading"
              class="btn btn-primary btn-block"
            >
              {{ !isLoading ? t('steps.tiles.buttons.next') : t('general.loading') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onBeforeMount, onUnmounted, ref, reactive, toRefs } from 'vue'
import { useToast } from 'vue-toastification'
import { isEmpty } from 'lodash/lang'
import { diff } from 'deep-object-diff'
import { useI18n } from 'vue-i18n'

import httpClient from '@/services/api/httpClient'
import useFormWizard from '@/hooks/formWizard'
import useFormState from '@/hooks/formState'

import TilesSelect from '@/components/Calculator/TilesSelect'

export default defineComponent({
  name: 'TilesStep',
  components: {
    TilesSelect
  },
  setup() {
    const { nextStep, prevStep, isLastStep, goToStep, currentStepNumber, steps } = useFormWizard()
    const { updateFormState, formState, getFieldValue } = useFormState()
    const toast = useToast()
    const { t } = useI18n()

    const surfaces = ref([])
    const isLoading = ref(false)

    const state = reactive({
      tile: getFieldValue('tile') || false
    })

    const selectedShape = reactive({
      tileShape: getFieldValue('tileShape') || false
    })

    onBeforeMount(() => {
      fetchStepData()
      document.addEventListener('keypress', submitHandler)
    })

    onUnmounted(() => {
      document.removeEventListener('keypress', submitHandler)
    })

    const submitHandler = e => e.key === 'Enter' && validateStep()

    const fetchStepData = async () => {
      try {
        const { data } = await httpClient.get('steps', 'tiles', {
          tile_shape: formState.tileShape.id
        })

        surfaces.value = data.data
        if (!getFieldValue('tile') && surfaces.value.length) {
          updateState(surfaces.value[0].tiles[0])
        }

        return Promise.resolve()
      } catch (error) {
        toast.error(t('errors.data'))
      }
    }

    const updateState = tile => {
      state.tile = tile
    }

    const stepBack = () => {
      const FLAT_TILE_SHPAE_ID = 1
      const isFlatTileSelected = selectedShape.tileShape.id === FLAT_TILE_SHPAE_ID
      const isGeneronPreviousStep = steps.value[currentStepNumber.value - 1] === 'Generon'

      if (isGeneronPreviousStep && !isFlatTileSelected) {
        goToStep(currentStepNumber.value - 2)
        return
      }

      prevStep()
    }

    const validateStep = async () => {
      if (isLoading.value) return

      try {
        const stored = {
          tile: getFieldValue('tile') || false
        }

        if (stored.tile && isEmpty(diff(stored, state))) {
          nextStep({ label: 'nem valtozott' })
          return Promise.resolve()
        }

        isLoading.value = true

        const data = {
          tile: state.tile.id
        }

        await httpClient.post('validate_steps/tile', data)

        updateFormState({ ...state })

        nextStep({ label: `${state.tile.title} ${state.tile.color}` })
      } catch (error) {
        toast.error(t('errors.save'))
      } finally {
        isLoading.value = false
      }
    }

    return {
      nextStep,
      stepBack,
      isLastStep,
      isLoading,
      surfaces,
      updateState,
      validateStep,
      ...toRefs(state),
      t
    }
  }
})
</script>
