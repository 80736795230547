<template>
  <div class="form-wizard-step">
    <div class="form-wizard-content text-center">
      <section>
        <div class="container-lg">
          <PackageSelect
            :packages="packages"
            :current="selectedPackage"
            :server-validation="serverValidation"
            @on-select="setSelectedPackage"
          />
        </div>
      </section>
    </div>

    <div class="form-wizard-footer">
      <div class="form-wizard-actions container">
        <div class="row">
          <div class="col-md-6 order-1 order-md-0">
            <button data-test="previous-step" @click="prevStep" class="btn btn-secondary btn-block">
              {{ t('steps.package.buttons.prev') }}
            </button>
          </div>
          <div class="col-md-6">
            <button
              data-test="next-step"
              @click="validateStep"
              :disabled="isLastStep"
              class="btn btn-primary btn-block"
            >
              {{ isLoading ? t('general.loading') : t('steps.package.buttons.next') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onBeforeMount, onUnmounted, reactive, toRefs } from 'vue'
import { useToast } from 'vue-toastification'
import { isEmpty } from 'lodash/lang'
import { diff } from 'deep-object-diff'
import { useI18n } from 'vue-i18n'

import httpClient from '@/services/api/httpClient'
import useFormWizard from '@/hooks/formWizard'
import useFormState from '@/hooks/formState'
import useFormControl from '@/hooks/formControl'

import PackageSelect from '@/components/Calculator/PackageSelect'

export default defineComponent({
  name: 'PackageStep',
  components: {
    PackageSelect
  },
  setup() {
    const { nextStep, prevStep, isLastStep } = useFormWizard()
    const { updateFormState, getFieldValue } = useFormState()
    const { serverValidation } = useFormControl()
    const toast = useToast()
    const { t } = useI18n()

    const isLoading = ref(false)
    const packages = ref([])

    const state = reactive({
      selectedPackage: getFieldValue('selectedPackage') || false
    })

    onBeforeMount(() => {
      fetchStepData()
      document.addEventListener('keypress', submitHandler)
    })

    onUnmounted(() => {
      document.removeEventListener('keypress', submitHandler)
    })

    const submitHandler = e => e.key === 'Enter' && validateStep()

    const fetchStepData = async () => {
      try {
        const { data } = await httpClient.get('steps/packages')

        packages.value = data.data

        if (!getFieldValue('selectedPackage') && packages.value.length) {
          setSelectedPackage(packages.value[0])
        }
      } catch (error) {
        toast.error(t('errors.data'))
      }
    }

    const validateStep = async () => {
      if (isLoading.value) return

      serverValidation.value = {}

      try {
        const stored = {
          selectedPackage: getFieldValue('selectedPackage') || false
        }

        if (stored.selectedPackage && isEmpty(diff(stored, state))) {
          await nextStep({ label: 'nem valtozott' })
          return Promise.resolve()
        }

        isLoading.value = true

        const data = {
          selected_package: state.selectedPackage.id
        }

        await httpClient.post('validate_steps/package', data)

        updateFormState({ selectedPackage: state.selectedPackage })

        await nextStep({ label: state.selectedPackage.title })

        return Promise.resolve()
      } catch (error) {
        if (error.errStatus === 422 || error.errStatus === 404) {
          serverValidation.value = Object.assign({}, error.errData.data.params)
        } else {
          toast.error(t('errors.save'))
        }
      } finally {
        isLoading.value = false
      }
    }

    const setSelectedPackage = async selectedPackage => {
      state.selectedPackage = selectedPackage
    }

    return {
      nextStep,
      prevStep,
      isLastStep,
      packages,
      setSelectedPackage,
      validateStep,
      ...toRefs(state),
      serverValidation,
      isLoading,
      t
    }
  }
})
</script>

<style lang="scss" scoped>
@import '@/assets/style/base/_variables.scss';

.gallery-container {
  background-image: $gradient-gray;
}
</style>
