<template>
  <div class="price-comparison">
    <div class="pricing-packages">
      <table class="pricing-table d-none d-md-block">
        <thead>
          <tr>
            <th>&nbsp;</th>
            <th>
              <div class="title">Classic 7</div>
              <span class="price-range"><strong>$</strong>$$$$</span>
            </th>
            <th>
              <div class="title">Comfort 15</div>
              <span class="price-range"><strong>$$</strong>$$$</span>
            </th>
            <th class="highlight">
              <span class="highlight-label">A gyártó ajánlása</span>
              <div class="title">Deluxe 20</div>
              <span class="price-range"><strong>$$$</strong>$$</span>
            </th>
            <th>
              <div class="title">TOP 15</div>
              <span class="price-range"><strong>$$$$</strong>$</span>
            </th>
            <th>
              <div class="title">Extreme 15</div>
              <span class="price-range">
                <strong>$$$$$</strong>
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Garancia</td>
            <td>7 év</td>
            <td>15 év</td>
            <td class="highlight">20 év</td>
            <td>15 év</td>
            <td>10 év</td>
          </tr>
          <tr>
            <td>Minimális tetőhajlásszög sík cserép esetén</td>
            <td>24</td>
            <td>24</td>
            <td class="highlight">24</td>
            <td>20</td>
            <td>16</td>
          </tr>
          <tr>
            <td>Minimális tetőhajlásszög hullámos cserép esetén</td>
            <td>20</td>
            <td>16</td>
            <td class="highlight">16</td>
            <td>12</td>
            <td>10</td>
          </tr>
          <tr>
            <td>Tetőfólia</td>
            <td>alap</td>
            <td>közepes</td>
            <td class="highlight">közepes</td>
            <td>magas</td>
            <td>extrém</td>
          </tr>
          <tr>
            <td>Kúpalátét</td>
            <td>alap</td>
            <td>magas</td>
            <td class="highlight">magas</td>
            <td>magas</td>
            <td>magas</td>
          </tr>
          <tr>
            <td>További kiegészítők</td>
            <td>további 4 féle kiegészítő</td>
            <td>további 5 féle kiegészítő</td>
            <td class="highlight">további 11 féle kiegészítő</td>
            <td>további 5 féle kiegészítő</td>
            <td>további 6 féle kiegészítő</td>
          </tr>
        </tbody>
      </table>

      <AccessoryTableAccordion />
    </div>
  </div>
</template>

<script>
import AccessoryTableAccordion from '@/components/Calculator/AccessoryTableAccordion.vue'

export default {
  name: 'AccesoryTable',
  components: {
    AccessoryTableAccordion
  }
}
</script>

<style lang="scss">
@import '@/assets/style/base/_variables.scss';

.price-comparison {
  @media screen and (max-width: 767px) {
    padding: 0;
  }

  @media screen and (min-width: 768px) {
    padding: 20px 0 0;
  }
}

.pricing-packages {
  @media screen and (max-width: 767px) {
    margin-top: 0;
  }

  @media screen and (min-width: 768px) {
    margin-top: 30px;
  }

  overflow: hidden;
  position: relative;

  .highlight-label {
    font-weight: 800;
    background: $black-two;
    color: $white;
    display: inline-block;
    text-transform: uppercase;
  }

  .price-range {
    color: #cbcbcb;
    display: block;

    strong {
      color: #000;
    }
  }

  .icon-checkmark {
    align-items: center;
    border-radius: 50%;
    color: $white;
    display: inline-flex;
    height: 20px;
    justify-content: center;
    width: 20px;
  }

  a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.pricing-table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  padding-bottom: 10px;

  tbody {
    tr {
      &:nth-child(odd) {
        background: linear-gradient(to right, rgba(0, 0, 0, 0) 15%, rgba(0, 0, 0, 0.1));
        background-attachment: fixed;
      }

      &:last-of-type {
        td.highlight {
          position: relative;
          background: transparent;

          &:after {
            border: solid $black-two;
            border-width: 0 2px 2px;
            bottom: -10px;
            content: '';
            height: 10px;
            left: -2px;
            position: absolute;
            right: -2px;
          }
        }
      }

      &:first-child {
        td {
          font-weight: bold;
        }
      }
    }
  }

  th {
    &:not(:first-child) {
      font-family: $font-family-heading-extrabold;

      .title {
        font-size: 16px;
        font-weight: 800;
        line-height: 1.75;
      }

      .price-range {
        font-size: 14px;
        letter-spacing: 0.8px;
      }
    }
  }

  th,
  td {
    padding: 15px 15px;

    &:first-child {
      padding-left: 0;
    }

    &:not(:first-child) {
      text-align: center;
    }

    &.highlight {
      background: #fff;
      border: solid $black-two;
      border-width: 0 2px;
    }
  }

  td {
    font-size: 12px;

    &:first-child {
      font-weight: 600;
    }
  }

  th {
    padding-bottom: 20px;
    vertical-align: bottom;

    &.highlight {
      border-top-width: 2px;
      padding-top: 0;
    }
  }

  .highlight-label {
    display: block;
    font-size: 8px;
    letter-spacing: 0.1em;
    margin: 0px -15px 15px;
    padding: 6px 15px;
  }
}
</style>
