<template>
  <div class="skeleton-loader">
    <div class="skeleton" v-for="index in parseInt(items, 10)" :key="index" :style="itemStyles">
      <Loader />
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue'
import Loader from '@/components/Global/Loader'

export default defineComponent({
  name: 'SkeletonLoader',
  components: { Loader },
  props: {
    items: {
      type: Number,
      default: () => 1
    },
    itemsPerRow: {
      type: Number,
      default: () => 1
    },
    disableComputedStyles: {
      type: Boolean,
      default: () => false
    }
  },
  setup(props) {
    const itemStyles = computed(() => {
      if (props.disableComputedStyles) {
        return {}
      }

      return {
        flex: `0 0 calc(100% / ${props.itemsPerRow})`,
        maxWidth: `calc(100% / ${props.itemsPerRow})`
      }
    })

    return {
      itemStyles
    }
  }
})
</script>

<style lang="scss">
.skeleton-loader {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.skeleton {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
