<template>
  <div class="form-wizard-step">
    <div class="form-wizard-content text-center">
      <section>
        <div class="container-lg">
          <div class="yt-embed-wrapper">
            <iframe
              :src="`https://www.youtube.com/embed/${generonOptions.videoId}?controls=0&autoplay=1`"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>

          <h2>{{ t('steps.generon.title') }}</h2>

          <GeneronOptionSelect
            :data="generonOptions"
            :current="generonOption"
            :server-validation="serverValidation"
            @on-select="setGeneronOption"
          />
        </div>
      </section>
    </div>

    <div class="form-wizard-footer">
      <div class="form-wizard-actions container">
        <div class="row">
          <div class="col-md-6 order-1 order-md-0">
            <button
              data-test="previous-step"
              @click="prevStep"
              :disabled="isLoading"
              class="btn btn-secondary btn-block"
            >
              {{ t('steps.generon.buttons.prev') }}
            </button>
          </div>
          <div class="col-md-6">
            <button
              data-test="next-step"
              @click="validateStep"
              :disabled="isLastStep || isLoading"
              class="btn btn-primary btn-block"
            >
              {{ isLoading ? t('general.loading') : t('steps.generon.buttons.next') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onBeforeMount, onUnmounted, reactive, toRefs } from 'vue'
import { useToast } from 'vue-toastification'
import { isEmpty } from 'lodash/lang'
import { diff } from 'deep-object-diff'
import { useI18n } from 'vue-i18n'

import httpClient from '@/services/api/httpClient'
import useFormWizard from '@/hooks/formWizard'
import useFormState from '@/hooks/formState'
import useFormControl from '@/hooks/formControl'

import GeneronOptionSelect from '@/components/Calculator/GeneronOptionSelect'

export default defineComponent({
  name: 'GeneronStep',
  components: {
    GeneronOptionSelect
  },
  setup() {
    const { nextStep, prevStep, isLastStep } = useFormWizard()
    const { updateFormState, getFieldValue } = useFormState()
    const { serverValidation } = useFormControl()
    const toast = useToast()
    const { t } = useI18n()

    const isLoading = ref(false)
    const generonOptions = ref({})

    const state = reactive({
      generonOption: getFieldValue('generonOption') || {}
    })

    onBeforeMount(() => {
      fetchStepData()
      document.addEventListener('keypress', submitHandler)
    })

    onUnmounted(() => {
      document.removeEventListener('keypress', submitHandler)
    })

    const submitHandler = e => e.key === 'Enter' && validateStep()

    const fetchStepData = async () => {
      try {
        const { data } = await httpClient.get('steps/generon')
        generonOptions.value = data.data
      } catch (error) {
        toast.error(t('errors.data'))
      }
    }

    const validateStep = async () => {
      if (isLoading.value) return

      if (!state.generonOption.id) {
        toast.warning(t('errors.select'))
        return
      }

      serverValidation.value = {}

      try {
        const stored = {
          generonOption: getFieldValue('generonOption') || false
        }

        if (stored.generonOption && isEmpty(diff(stored, state))) {
          nextStep({ label: 'nem valtozott' })
          return Promise.resolve()
        }

        isLoading.value = true

        const data = {
          generon_option: state.generonOption.id
        }

        await httpClient.post('validate_steps/generon', data)

        updateFormState({ generonOption: state.generonOption })

        nextStep({ label: state.generonOption.label })
        return Promise.resolve()
      } catch (error) {
        if (error.errStatus === 422 || error.errStatus === 404) {
          serverValidation.value = Object.assign({}, error.errData.data.params)
        } else {
          toast.error(t('errors.save'))
        }
      } finally {
        isLoading.value = false
      }
    }

    const setGeneronOption = async generonOption => {
      state.generonOption = generonOption
    }

    return {
      nextStep,
      prevStep,
      isLastStep,
      generonOptions,
      setGeneronOption,
      validateStep,
      ...toRefs(state),
      serverValidation,
      isLoading,
      t
    }
  }
})
</script>

<style lang="scss" scoped>
@import '@/assets/style/base/_variables.scss';

.form-video {
  max-width: 750px;
  width: 100%;
  height: auto;
}

h2 {
  margin-top: 40px;
}
</style>
