<template>
  <div class="form-wizard-step">
    <div class="form-wizard-content">
      <div class="container">
        <div class="form-wizard-header text-center">
          <h2>{{ t('steps.questions.title') }}</h2>
        </div>
        <span v-if="questions && questions.length">
          <component :is="component" :questions="questions" :current="answers" :server-validation="serverValidation" />
        </span>
        <Loader class="text-center mt-5" v-else />
      </div>
    </div>

    <div class="form-wizard-footer">
      <div class="form-wizard-actions container">
        <div class="row">
          <div class="col-md-6 order-1 order-md-0">
            <button
              data-test="previous-step"
              @click="prevStep"
              :disabled="isLoading"
              class="btn btn-secondary btn-block"
            >
              {{ t('steps.questions.buttons.prev') }}
            </button>
          </div>
          <div class="col-md-6">
            <button
              data-test="next-step"
              @click="submit"
              :disabled="isLastStep || isLoading"
              class="btn btn-primary btn-block"
            >
              {{ !isLoading ? t('steps.questions.buttons.next') : t('general.loading') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, onBeforeMount, onUnmounted, toRefs, ref } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { useToast } from 'vue-toastification'
import { useI18n } from 'vue-i18n'

import httpClient from '@/services/api/httpClient'
import useGallery from '@/hooks/gallery'
import useFormWizard from '@/hooks/formWizard'
import useFormState from '@/hooks/formState'
import useFormControl from '@/hooks/formControl'
import useGtmEvent from '@/hooks/gtm'

import Loader from '@/components/Global/Loader.vue'
import QuestionsList from '@/components/Calculator/QuestionsList.vue'
import QuestionsListSr from '@/components/Calculator/QuestionsListSr.vue'

export default defineComponent({
  name: 'QuestionsStep',
  components: {
    Loader,
    QuestionsList,
    QuestionsListSr
  },
  setup() {
    const { nextStep, prevStep, isLastStep, currentStepNumber } = useFormWizard()
    const { formState, updateFormState, getFieldValue } = useFormState()
    const { serverValidation } = useFormControl()
    const { updateGallery } = useGallery()
    const toast = useToast()
    const { trackStep } = useGtmEvent()
    const { t } = useI18n()

    const currentLanguage = localStorage.getItem('language') || process.env.VUE_APP_DEFAULT_LOCALE

    const isLoading = ref(false)
    const validations = useVuelidate()

    const state = reactive({
      questions: [],
      answers: getFieldValue('questions') || {}
    })

    const languageComponents = {
      sr: 'QuestionsListSr',
      hr: 'QuestionsListSr'
    }

    const component = languageComponents[currentLanguage] ?? 'QuestionsList'

    onBeforeMount(() => {
      fetchStepData()
      document.addEventListener('keypress', submitHandler)
    })

    onUnmounted(() => {
      document.removeEventListener('keypress', submitHandler)
    })

    const submitHandler = e => e.key === 'Enter' && submit()

    const fetchStepData = async () => {
      if (isLoading.value) return

      try {
        const { data } = await httpClient.get('steps', 'questions', { tile_shape: formState.tileShape.id })
        state.questions = data.data

        if (!getFieldValue('questions')) {
          state.questions.forEach(question => {
            state.answers[question.id] = []
          })
        }

        return Promise.resolve()
      } catch (error) {
        toast.error(t('errors.data'))
      } finally {
        isLoading.value = false
      }
    }

    const submit = async () => {
      if (isLoading.value) return

      await validations.value.$validate()

      if (validations.value.$invalid) return

      try {
        isLoading.value = true

        const answers = JSON.parse(JSON.stringify(state.answers))
        const entries = Object.entries(answers)

        let questionsPayload = {}

        entries.forEach(([key, value], index) => {
          questionsPayload[index] = {
            id: key,
            values: Array.isArray(value) ? [...value] : [value]
          }
        })

        await httpClient.post('validate_steps/questions', questionsPayload)
        updateFormState({ questions: answers })

        const calculatePayload = {
          degree: formState.degree,
          roof_type: formState.roofType.id,
          sides: formState.sides,
          tile_shape: formState.tileShape.id,
          tile: formState.tile.id,
          generon_option: formState.generonOption?.id,
          gutter_option: formState.gutterOption?.id,
          answers: questionsPayload,
          selected_package: formState.selectedPackage?.id
        }

        const { data } = await httpClient.post('calculate', calculatePayload)

        updateFormState({ results: data.data })
        updateGallery({ galleryItems: data.data.gallery })

        nextStep({ label: JSON.stringify(questionsPayload) })

        trackStep({
          category: `step${currentStepNumber.value + 2}`,
          action: 'veglegesites',
          label: `${data.data.tile.title} ${data.data.tile.color}`
        })

        return Promise.resolve()
      } catch (error) {
        if (error.errStatus === 422 || error.errStatus === 404) {
          serverValidation.value = Object.assign({}, error.errData.data.params)
        } else {
          toast.error(t('errors.save'))
        }
      } finally {
        isLoading.value = false
      }
    }

    return {
      nextStep,
      prevStep,
      isLastStep,
      ...toRefs(state),
      submit,
      isLoading,
      serverValidation,
      t,
      component
    }
  }
})
</script>
