<template>
  <div class="form-component">
    <h2>{{ t('steps.package.title') }}</h2>
    <p>{{ t('steps.package.description') }}</p>

    <div class="package-select list">
      <div class="d-flex flex-wrap justify-content-center">
        <div
          v-for="pack in packages"
          :key="pack.id"
          @click="update(pack)"
          class="package-select list-item d-flex flex-column justify-content-between"
          :class="{
            selected: isSelected(pack)
          }"
        >
          <div class="detail-header">
            <strong class="label">{{ pack.title }}</strong>
            <span class="price-range">
              <strong>{{ getDollars(pack.id, packages.length, true) }}</strong
              >{{ getDollars(pack.id, packages.length, false) }}
            </span>
          </div>

          <div class="detail-item" v-for="detail in pack.details" :key="detail.id">
            <SurfaceFeatureIcon color="#fdab1c" />
            {{ detail.label }}
          </div>
        </div>
      </div>
    </div>
    <ServerValidation field="selected_package" :server-validation="serverValidation" />
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue'
import { useI18n } from 'vue-i18n'

import ServerValidation from '@/components/Global/ServerValidation'
import SurfaceFeatureIcon from '@/components/Calculator/SurfaceFeatureIcon.vue'

export default defineComponent({
  name: 'PackageSelect',
  components: {
    SurfaceFeatureIcon,
    ServerValidation
  },
  emits: ['on-select'],
  props: {
    packages: {
      type: Array,
      required: true
    },
    current: {
      type: [Object, Boolean],
      required: true
    },
    serverValidation: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    getDollars(id, itemCount, active) {
      const dollar = '$'
      const amount = active ? Array.from(Array(id).keys()) : Array.from(Array(itemCount - id).keys())
      return amount.map(() => dollar).join('')
    }
  },
  setup(props, { emit }) {
    const { t } = useI18n()
    const currentLanguage = localStorage.getItem('language') || process.env.VUE_APP_DEFAULT_LOCALE

    const selectedPackage = computed(() => props.current)

    const isSelected = ({ id }) => {
      return selectedPackage.value.id === id
    }

    const update = pack => {
      if (!pack) return

      emit('on-select', pack)
    }

    return {
      isSelected,
      update,
      t,
      currentLanguage
    }
  }
})
</script>

<style lang="scss" scoped>
@import '@/assets/style/base/_variables.scss';

h2 {
  margin-top: 60px;
}

.form-component {
  :deep(.skeleton-loader) {
    margin-left: -15px;
    margin-right: -15px;
  }

  :deep(.skeleton) {
    width: 300px;
    height: 165px;
    margin: 15px;
    border: 2px solid #ededed;
  }
}

.package-select {
  &.list {
    margin: 40px 0 80px;
  }

  .detail-item {
    padding-left: 40px;
  }

  .list-item {
    position: relative;
    height: auto;
    padding: 0 0 20px;
    justify-content: flex-start !important;
    align-items: flex-start;

    @media screen and (max-width: 991px) {
      width: 100%;
    }

    @media screen and (min-width: 992px) {
      width: 300px;
      margin: 0 30px 30px 0;
    }

    .detail-header {
      font-family: $font-family-heading-extrabold;
      align-self: center;
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
      align-content: center;
      margin-bottom: 1rem;
    }

    .label {
      font-family: inherit;
      margin-top: 30px;
      font-size: 24px;
      line-height: 120%;
      align-self: center;
    }

    .price-range {
      font-size: 14px;
      letter-spacing: 0.8px;
      align-self: center;
      color: #cbcbcb;

      strong {
        color: #000;
      }
    }

    .feature {
      &__icon {
        margin-right: 8px;
      }
    }
  }
}
</style>
