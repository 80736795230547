<template>
  <div class="questions">
    <div v-for="question in filteredQuestions" :key="question.id" class="question">
      <h3 class="question-title">{{ question.title }}</h3>
      <p v-if="question.description">{{ question.description }}</p>
      <div class="question-list">
        <label
          v-for="option in question.options"
          :key="option.id"
          class="question-option"
          :class="`question-option--${question.type}`"
        >
          <input
            v-model="validations[question.id].$model"
            :type="question.type"
            :value="option.id"
            :name="question.id"
            @change="updateValidator(question)"
          />
          <span class="field-decorator"></span>
          {{ option.label }}
        </label>
        <ErrorMessage :field="validations[question.id]" />
      </div>
    </div>
    <ServerValidation field="questions" :server-validation="serverValidation" />
  </div>
</template>

<script>
import { defineComponent, reactive, computed, toRefs, ref } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { requiredIf } from '@vuelidate/validators'

import ErrorMessage from '@/components/Global/ErrorMessage.vue'
import ServerValidation from '@/components/Global/ServerValidation'

export default defineComponent({
  components: {
    ErrorMessage,
    ServerValidation
  },
  props: {
    questions: {
      type: Array,
      required: true
    },
    current: {
      type: Object,
      required: true
    },
    serverValidation: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    const state = reactive({
      validations: null
    })

    const localQuestions = ref(props.questions)

    const filteredQuestions = computed(() =>
      localQuestions.value.filter(
        question =>
          !question.dependency ||
          question.dependency.optionId === state.validations[question.dependency.questionId].$model
      )
    )

    const currentData = computed(() => props.current)

    const rules = computed(() => {
      const ruleSet = {}
      props.questions.forEach(question => {
        ruleSet[question.id] = {
          requiredIf: requiredIf(() => {
            if (!question.dependency) {
              return question.required
            }

            if (question.dependency.optionId === currentData.value[question.dependency.questionId]) {
              return question.required
            }

            return false
          })
        }
      })
      return ruleSet
    })

    state.validations = useVuelidate(rules.value, currentData)

    const updateValidator = question => {
      props.questions.forEach(item => {
        if (item.dependency && item.dependency.questionId === question.id) {
          state.validations[item.id].$reset()
        }
      })
    }

    return {
      currentData,
      ...toRefs(state),
      localQuestions,
      filteredQuestions,
      updateValidator
    }
  }
})
</script>

<style lang="scss" scoped>
@import '@/assets/style/base/_variables.scss';

.questions {
  margin-top: 50px;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  font-size: 16px;
}

.question {
  &:not(:first-child) {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 2px solid #ededed;
  }

  &-option {
    position: relative;
    cursor: pointer;
    padding: 4px 0;

    .field-decorator {
      position: absolute;
      left: 0;
      width: 18px;
      height: 18px;
      top: 7px;

      &:before,
      &:after {
        content: '';
        display: block;
        position: absolute;
        transition: 0.1s ease-out;
      }

      &:before {
        border: 2px solid #ededed;
        width: 100%;
        height: 100%;
        left: 0;
      }

      &:after {
        left: 4px;
        background: $yellowish-orange;
        width: 10px;
        height: 10px;
        top: 4px;
        transform: scale(0);
      }
    }

    &--radio {
      .field-decorator {
        &:before,
        &:after {
          border-radius: 50%;
        }
      }
    }
  }
}

.question-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

[type='radio'],
[type='checkbox'] {
  opacity: 0;
  visibility: hidden;
  display: inline-block;
  width: 18px;
  margin-right: 6px;

  &:checked + .field-decorator {
    &:before {
      border-color: $yellowish-orange;
    }

    &:after {
      transform: scale(1);
    }
  }
}
</style>
