<template>
  <section class="price-section">
    <div class="banner d-flex flex-column flex-md-row">
      <div class="banner__content banner__content--full text-center text-md-left">
        <strong class="banner__title">{{
          isSale
            ? t('banners.roofPrice.boxTitleSale', {
                tile: `${formState.results.tile.title} ${formState.results.tile.color}`
              })
            : t('banners.roofPrice.boxTitle')
        }}</strong>
        <p>{{ t('banners.roofPrice.title') }}</p>
        <div class="banner__price">
          <strong>
            {{ formState.results.price.min }} {{ t('general.currency') }} -
            <wbr />
            {{ formState.results.price.max }}
            {{ t('general.currency') }}
            <span class="banner__price--icon">*</span>
          </strong>
        </div>
        <p v-if="isSale" class="banner__sale-text" v-html="t('banners.roofPrice.saleText')" />
        <p class="banner__text">* {{ t('banners.roofPrice.description') }}</p>
      </div>
    </div>
    <div v-if="formState.gutterOption?.interested" class="banner d-flex flex-column flex-md-row">
      <div class="banner__content banner__content--full text-center text-md-left">
        <strong class="banner__title">{{ t('banners.roofPrice.gutter.boxTitle') }}</strong>
        <p>{{ t('banners.roofPrice.gutter.title') }}</p>
        <div class="banner__price">
          <strong>
            {{ formState.results.gutter_price.min }} {{ t('general.currency') }} -
            <wbr />
            {{ formState.results.gutter_price.max }} {{ t('general.currency') }}
            <span class="banner__price--icon">*</span>
          </strong>
        </div>
        <p class="banner__text">* {{ t('banners.gutterPrice.description') }}</p>
        <a :href="t('banners.gutterPrice.cta.link')" target="_blank" class="btn btn-dark">
          {{ t('banners.gutterPrice.cta.label') }}
        </a>
      </div>
    </div>
    <div class="banner__actions no-print">
      <button class="btn btn-outline-dark" @click="showModal = true">
        <em class="icon-email"></em>
        {{ t('banners.roofPrice.buttons.sendEmail') }}
      </button>
      <Modal v-if="showModal" @close="resetForm">
        <template v-slot:header>
          <h3 class="text-center">{{ t('banners.roofPrice.modal.title') }}</h3>
        </template>

        <template v-slot:body>
          <form class="modal__form" novalidate @submit.prevent="sendEmail">
            <div class="form-group">
              <label for="name">{{ t('fields.name') }}</label>
              <input
                v-model="validations.name.$model"
                type="text"
                id="name"
                name="name"
                class="form-control"
                :class="{ 'is-invalid': validations.name.$error || hasServerValidationError('name') }"
              />
              <ErrorMessage :field="validations.name" />
              <ServerValidation field="name" :server-validation="serverValidation" />
            </div>
            <div v-if="isCityFieldEnabled" class="form-group">
              <label for="name">{{ t('fields.city') }}</label>
              <Autocomplete
                v-model="validations.city.$model"
                @input="filterCities"
                @on-select="selectCity"
                :results="filteredCities"
                :error-state="validations.city.$error || hasServerValidationError('city')"
              />
              <ErrorMessage :field="validations.city" />
              <ServerValidation field="city" :server-validation="serverValidation" />
            </div>
            <div class="form-group">
              <label for="email">{{ t('fields.email') }}</label>
              <input
                v-model="validations.email.$model"
                type="email"
                id="email"
                name="email"
                class="form-control"
                :class="{ 'is-invalid': validations.email.$error || hasServerValidationError('email') }"
              />
              <ErrorMessage :field="validations.email" />
              <ServerValidation field="email" :server-validation="serverValidation" />
            </div>
            <div class="form-group">
              <label class="custom-label">
                <input v-model="validations.privacyPolicy.$model" type="checkbox" name="privacy" />
                <span class="field-decorator"></span>
                <span v-html="t('banners.roofPrice.modal.privacyPolicy')"></span>
              </label>
              <ErrorMessage :field="validations.privacyPolicy" />
            </div>
            <button type="submit" class="btn btn-primary btn-block" :disabled="isLoading">
              {{ t('banners.roofPrice.modal.submit.label') }}
            </button>
          </form>
        </template>
      </Modal>
      <button class="btn btn-outline-dark btn-icon" @click="printPage">
        <em class="icon-printer"></em>
      </button>
    </div>
  </section>
</template>

<script>
import { defineComponent, reactive, toRefs, onMounted } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { useToast } from 'vue-toastification'
import { useI18n } from 'vue-i18n'

import useFormState from '@/hooks/formState'
import useFormControl from '@/hooks/formControl'
import httpClient from '@/services/api/httpClient'
import useGtmEvent from '@/hooks/gtm'

import Modal from '@/components/Global/Modal.vue'
import ErrorMessage from '@/components/Global/ErrorMessage.vue'
import ServerValidation from '@/components/Global/ServerValidation.vue'
import Autocomplete from '@/components/Global/Autocomplete.vue'

export default defineComponent({
  name: 'RoofPriceBanner',
  components: {
    Modal,
    ErrorMessage,
    ServerValidation,
    Autocomplete
  },
  setup() {
    const { formState } = useFormState()
    const { serverValidation, hasServerValidationError } = useFormControl()
    const toast = useToast()
    const { trackEvent } = useGtmEvent()
    const { t } = useI18n()
    const currentLanguage = localStorage.getItem('language') || process.env.VUE_APP_DEFAULT_LOCALE
    const isCityFieldEnabled = currentLanguage === 'sk'

    const state = reactive({
      showModal: false,
      email: '',
      name: '',
      city: null,
      privacyPolicy: false,
      isLoading: false,
      cities: [],
      filteredCities: [],
      isSale: false
    })

    function printPage() {
      trackEvent({
        category: 'nyomtatás',
        action: 'kattintas'
      })

      window.print()
    }

    const validationRules = {
      email: {
        required,
        email
      },
      name: {
        required
      },
      privacyPolicy: {
        required: val => val === true
      }
    }

    if (isCityFieldEnabled) {
      validationRules.city = { required }
    }

    const validations = useVuelidate(validationRules, state)

    async function fetchCities() {
      try {
        const { data } = await httpClient.get('cities')
        state.cities = data.data
        return Promise.resolve()
      } catch (error) {
        return Promise.reject(error)
      }
    }

    function isSale() {
      return false
    }

    onMounted(() => {
      isCityFieldEnabled && !state.cities.length && fetchCities()
      state.isSale = isSale()
    })

    function filterCities(search) {
      state.filteredCities = [...state.cities]
        .filter(city => city.name.toLowerCase().includes(search.toLowerCase()))
        .map(city => city.name)
    }

    function selectCity(item) {
      state.city = [...state.cities].find(city => city.name.toLowerCase() === item.toLowerCase())
    }

    const resetForm = () => {
      state.email = ''
      state.name = ''
      state.privacyPolicy = false
      state.showModal = false
      state.city = null

      setTimeout(() => {
        validations.value.email.$reset()
        validations.value.name.$reset()
        validations.value.privacyPolicy.$reset()
        validations.value.$reset()
      }, 10)
    }

    async function sendEmail() {
      if (state.isLoading) return

      validations.value.$touch()

      if (validations.value.$invalid) return

      try {
        state.isLoading = true
        serverValidation.value = {}

        const payload = {
          email: state.email,
          name: state.name,
          city: state.city?.id,
          calculation_id: formState.results.calculation_id,
          degree: formState.degree,
          roof_type: formState.roofType.id,
          sides: formState.sides,
          tile_shape: formState.tileShape.id,
          tile: formState.tile.id,
          selected_package: formState.selectedPackage?.id
        }

        const params = {
          email: true
        }

        await httpClient.post('calculate', payload, params)

        trackEvent({
          category: 'e-mail',
          action: 'kuldes'
        })

        toast.success(t('banners.roofPrice.modal.submit.success', { email: state.email }))
        resetForm()
      } catch (error) {
        if (error.errStatus === 404) {
          serverValidation.value = Object.assign({}, error.errData.error)
        }
        if (error.errStatus === 422) {
          serverValidation.value = Object.assign({}, error.errData.data.data.params)
        }
      } finally {
        state.isLoading = false
      }
    }

    return {
      ...toRefs(state),
      sendEmail,
      validations,
      serverValidation,
      hasServerValidationError,
      resetForm,
      printPage,
      t,
      filterCities,
      selectCity,
      currentLanguage,
      formState,
      isCityFieldEnabled
    }
  }
})
</script>

<style lang="scss" scoped>
@import '@/assets/style/base/_variables.scss';

.price-section {
  display: flex;
  flex-wrap: wrap;
  gap: 0 30px;

  .generon-price-section & {
    flex-direction: column;
  }

  .banner {
    flex: 1;
  }

  .banner__actions {
    flex: 1 0 100%;
    display: flex;
  }
}

.banner {
  margin-top: 30px;

  &__content {
    border-color: $white-two;
    border-style: solid;
    border-width: 2px 2px 0 2px;
    width: 100%;
    padding: 30px;

    @media (min-width: 765px) {
      width: 55%;
      border-width: 2px 0 2px 2px;
    }

    @media (min-width: 992px) {
      width: 67%;
    }

    &--full {
      width: 100%;
      border-width: 2px;
    }
  }

  strong {
    font-family: $font-family-bold;
  }

  &__title {
    font-size: 26px;
    margin-bottom: 10px;
  }

  &__sale-text {
    font-size: 20px;
    line-height: 1.3;
    font-family: $font-family-bold;
    margin-top: 1rem;
  }

  &__price {
    font-size: 30px;
    white-space: nowrap;
    line-height: 1.25;

    @media (min-width: 992px) {
      font-size: 40px;
      margin-bottom: 5px;
    }

    &--icon {
      color: #aaa;
    }
  }

  small {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0;
  }

  &__actions {
    margin-top: 20px;

    @media (max-width: 992px) {
      margin-bottom: 30px;
    }

    .btn-icon {
      padding: 12px 12px 13px 12px;
      margin-left: 10px;
    }

    .icon-email,
    .icon-printer {
      width: 20px;
      filter: invert(1);
    }

    .icon-email {
      height: 15px;
      margin-right: 10px;
    }

    .icon-printer {
      height: 20px;
    }
  }

  &__coupon {
    position: relative;
    background-image: url('~@/assets/images/summary/kincseshaz-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    color: $white;
    border-top: 2px dotted $black;
    text-align: center;
    width: 100%;
    padding: 60px 0;

    @media (min-width: 765px) {
      border-left: 2px dotted $black;
      border-top: 0;
      width: 45%;
      padding: 0;
    }

    @media (min-width: 992px) {
      width: 33%;
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      background-color: $white;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 2px solid $white-two;
    }

    &::before {
      top: -15px;
      left: -15px;
      border-left-color: transparent;
      border-top-color: transparent;
      transform: rotate(-45deg);

      @media (min-width: 765px) {
        border-right-color: transparent;
        border-left-color: $white-two;
      }
    }

    &::after {
      top: -14px;
      right: -15px;
      border-bottom-color: transparent;
      border-right-color: transparent;
      transform: rotate(-45deg);

      @media (min-width: 765px) {
        top: auto;
        bottom: -15px;
        left: -15px;
        border-left-color: transparent;
      }
    }

    .coupon__title {
      display: block;
      font-family: $font-family-bold;
      font-size: 26px;
      margin-bottom: 10px;

      @media (min-width: 992px) {
        padding: 0 50px;
      }
    }
  }

  &__sign {
    position: absolute;
    bottom: 0;
    left: -35px;

    @media (max-width: 765px) {
      max-width: 80px;
      left: -10px;
    }
  }

  &__link {
    text-transform: uppercase;
    font-family: $font-family-heading-extrabold;
    font-size: 11px;
    text-decoration: underline;
    color: $white;
    letter-spacing: 0.92px;
    padding: 5px 0;
  }
}

.modal__form {
  max-width: 300px;
  margin: 20px auto 0 auto;
}
</style>
