<template>
  <div class="loan-scheme-banner no-print">
    <div class="row">
      <div class="col-md-6">
        <div class="loan-scheme">
          <h2 v-html="$t('banners.cetelemCalculator.zeroThm.title')" />
          <p>{{ $t('banners.cetelemCalculator.zeroThm.description') }}</p>
        </div>
        <iframe
          :src="zeroThm"
          style="border: 0 none"
          width="100%"
          height="280"
          sandbox="allow-top-navigation allow-top-navigation-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
        />
      </div>
      <div class="col-md-6 mt-4 mt-md-0">
        <div class="loan-scheme">
          <h2 v-html="$t('banners.cetelemCalculator.normalThm.title')" />
          <p>{{ $t('banners.cetelemCalculator.normalThm.description') }}</p>
        </div>
        <iframe
          :src="normalThm"
          style="border: 0 none"
          width="100%"
          height="280"
          sandbox="allow-top-navigation allow-top-navigation-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CetelemCalculatorBanner',
  setup() {
    const params = {
      url: 'https://ecom2demo.cetelem.hu/ecommerce/Calc.action',
      shopCode: 2114932,
      purchaseAmount: 2000000,
      zeroBaremId: 222,
      normalBaremId: 176
    }

    let zeroThm = `${params.url}?shopCode=${params.shopCode}&barem=${params.zeroBaremId}&purchaseAmount=${params.purchaseAmount}`

    const normalThm = `${params.url}?shopCode=${params.shopCode}&barem=${params.normalBaremId}&purchaseAmount=${params.purchaseAmount}`

    return {
      zeroThm,
      normalThm
    }
  }
})
</script>

<style scoped lang="scss">
@import '@/assets/style/base/_variables.scss';

.loan-scheme-banner {
  margin-top: 65px;
  margin-bottom: 65px;
}

.loan-scheme {
  background-color: $white-two;
  padding: 20px 15px;
  text-align: center;

  h2 {
    font-size: 26px;
    margin-bottom: 0;
  }

  p {
    margin-bottom: 0;
    text-transform: uppercase;
  }
}
</style>
