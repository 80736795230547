<template>
  <div class="loader">
    <img src="@/assets/images/loader.svg" :alt="t('general.loading')" />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'LoaderComponent',
  setup() {
    const { t } = useI18n()

    return {
      t
    }
  }
})
</script>

<style lang="scss" scoped>
.loader {
  transition: 0.3s ease-out;
  width: 100%;

  img {
    animation: loader-animation 1.5s ease-out infinite;
  }
}
</style>
