<template>
  <div class="form-wizard">
    <WizardSteps v-if="!isLastStep" :current-step="currentStepNumber" :steps="steps" />

    <div class="form-wizard-main">
      <Gallery v-if="displayGallery" @on-select="provideSelection" />
      <component :is="currentStep" ref="currentStepComponent" />
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, computed } from 'vue'

import useFormWizard from '@/hooks/formWizard'
import useFormState from '@/hooks/formState'

import { LANGUAGE_CONFIG } from '@/services/language'

import WizardSteps from '@/components/Global/WizardSteps'
import RoofType from '@/components/Calculator/Steps/RoofType'
import Measurements from '@/components/Calculator/Steps/Measurements'
import TileShape from '@/components/Calculator/Steps/TileShape'
import Generon from '@/components/Calculator/Steps/Generon'
import Gutter from '@/components/Calculator/Steps/Gutter'
import Tiles from '@/components/Calculator/Steps/Tiles'
import Accessory from '@/components/Calculator/Steps/Accessory'
import Questions from '@/components/Calculator/Steps/Questions'
import SummaryStep from '@/components/Calculator/Steps/Summary'
import Package from '@/components/Calculator/Steps/Package'
import Gallery from '@/components/Global/Gallery'

export default defineComponent({
  name: 'HomeView',
  components: {
    WizardSteps,
    RoofType,
    Measurements,
    TileShape,
    Generon,
    Gutter,
    Tiles,
    Accessory,
    Questions,
    SummaryStep,
    Gallery,
    Package
  },
  setup() {
    const { currentStep, currentStepNumber, currentStepComponent, setSteps, isLastStep, goToStep } = useFormWizard()
    const { updateFormState } = useFormState()
    const currentLanguage = localStorage.getItem('language') || process.env.VUE_APP_DEFAULT_LOCALE

    const steps = LANGUAGE_CONFIG[currentLanguage].steps

    const displayGallery = computed(() => ['TileShape', 'Tiles', 'SummaryStep'].includes(currentStep.value))

    const provideSelection = item => {
      if (['Tiles'].includes(currentStep.value)) {
        currentStepComponent.value.updateState(item)
      }
    }

    const url = new URL(window.location.href)
    const stepParam = url.searchParams.get('step')
    const storedFormState = JSON.parse(localStorage.getItem('tc_form'))

    if (stepParam && storedFormState) {
      updateFormState({ ...storedFormState })
      goToStep(stepParam)
    }

    onMounted(() => setSteps(steps))

    return {
      currentStep,
      currentStepNumber,
      currentStepComponent,
      steps,
      isLastStep,
      displayGallery,
      provideSelection
    }
  }
})
</script>
