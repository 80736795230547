<template>
  <Accordion class="pricing-accordion d-block d-md-none">
    <AccordionPanel class="pricing-package accordion">
      <AccordionPanelHeader class="pricing-package__title accordion__trigger">
        <span class="title">Classic 7</span>
        <span class="price-range"><strong>$</strong>$$$$</span>
      </AccordionPanelHeader>
      <AccordionPanelContent class="accordion__body">
        <div class="pricing-package__data-row">7 év Garancia</div>
        <div class="pricing-package__data-row">24 fok minimális tetőhajlásszög sík cserép esetén</div>
        <div class="pricing-package__data-row">20 fok minimális tetőhajlásszög hullámos cserép esetén</div>
        <div class="pricing-package__data-row">alap tetőfólia</div>
        <div class="pricing-package__data-row">alap kúpalátét</div>
        <div class="pricing-package__data-row">további 4 féle kiegészítő</div>
      </AccordionPanelContent>
    </AccordionPanel>

    <AccordionPanel class="pricing-package accordion">
      <AccordionPanelHeader class="pricing-package__title accordion__trigger">
        <span class="title">Comfort 15</span>
        <span class="price-range"><strong>$$</strong>$$$</span>
      </AccordionPanelHeader>
      <AccordionPanelContent class="accordion__body">
        <div class="pricing-package__data-row">15 év Garancia</div>
        <div class="pricing-package__data-row">24 fok minimális tetőhajlásszög sík cserép esetén</div>
        <div class="pricing-package__data-row">16 fok minimális tetőhajlásszög hullámos cserép esetén</div>
        <div class="pricing-package__data-row">közepes tetőfólia</div>
        <div class="pricing-package__data-row">magas kúpalátét</div>
        <div class="pricing-package__data-row">további 5 féle kiegészítő</div>
      </AccordionPanelContent>
    </AccordionPanel>

    <AccordionPanel class="pricing-package accordion">
      <AccordionPanelHeader class="pricing-package__title accordion__trigger">
        <span class="title">Deluxe 20</span>
        <span class="price-range"><strong>$$$</strong>$$</span>
      </AccordionPanelHeader>
      <AccordionPanelContent class="accordion__body">
        <div class="pricing-package__data-row">20 év Garancia</div>
        <div class="pricing-package__data-row">24 fok minimális tetőhajlásszög sík cserép esetén</div>
        <div class="pricing-package__data-row">16 fok minimális tetőhajlásszög hullámos cserép esetén</div>
        <div class="pricing-package__data-row">közepes tetőfólia</div>
        <div class="pricing-package__data-row">magas kúpalátét</div>
        <div class="pricing-package__data-row">további 11 féle kiegészítő</div>
      </AccordionPanelContent>
    </AccordionPanel>

    <AccordionPanel class="pricing-package accordion">
      <AccordionPanelHeader class="pricing-package__title accordion__trigger">
        <span class="title">Top 15</span>
        <span class="price-range"><strong>$$$$</strong>$</span>
      </AccordionPanelHeader>
      <AccordionPanelContent class="accordion__body">
        <div class="pricing-package__data-row">15 év garancia</div>
        <div class="pricing-package__data-row">20 fok minimális tetőhajlásszög sík cserép esetén</div>
        <div class="pricing-package__data-row">12 fok minimális tetőhajlásszög hullámos cserép esetén</div>
        <div class="pricing-package__data-row">magas tetőfólia</div>
        <div class="pricing-package__data-row">magas kúpalátét</div>
        <div class="pricing-package__data-row">további 5 féle kiegészítő</div>
      </AccordionPanelContent>
    </AccordionPanel>

    <AccordionPanel class="pricing-package accordion">
      <AccordionPanelHeader class="pricing-package__title accordion__trigger">
        <span class="title">Extreme 15</span>
        <span class="price-range"><strong>$$$$$</strong></span>
      </AccordionPanelHeader>
      <AccordionPanelContent class="accordion__body">
        <div class="pricing-package__data-row">10 év garancia</div>
        <div class="pricing-package__data-row">16 fok minimális tetőhajlásszög sík cserép esetén</div>
        <div class="pricing-package__data-row">10 fok minimális tetőhajlásszög hullámos cserép esetén</div>
        <div class="pricing-package__data-row">extrém tetőfólia</div>
        <div class="pricing-package__data-row">magas kúpalátét</div>
        <div class="pricing-package__data-row">további 6 féle kiegészítő</div>
      </AccordionPanelContent>
    </AccordionPanel>
  </Accordion>
</template>

<script>
import Accordion from '@/components/Accordion/accordion'
import AccordionPanel from '@/components/Accordion/accordion-panel'
import AccordionPanelContent from '@/components/Accordion/accordion-panel-content'
import AccordionPanelHeader from '@/components/Accordion/accordion-panel-header'

export default {
  name: 'AccessoryTableAccordion',
  components: {
    Accordion,
    AccordionPanel,
    AccordionPanelContent,
    AccordionPanelHeader
  }
}
</script>

<style lang="scss">
@import '@/assets/style/base/_variables.scss';

.accordion__trigger {
  cursor: pointer;
}

.accordion__content {
  opacity: 0;
  transition: 0.3s ease-in-out;

  .is-active & {
    opacity: 1;
  }
}

.pricing-accordion {
  padding: 20px 0 0;

  .pricing-package {
    &:not(:first-child) {
      border-top: 2px solid #ededed;
      margin-top: 15px;
      padding-top: 15px;
    }

    &.is-active {
      .pricing-package__title:after {
        transform: rotate(-180deg);
      }
    }

    &__title {
      position: relative;
      font-family: $font-family-heading-extrabold;

      .title {
        font-size: 16px;
        font-weight: 800;
        line-height: 1.75;
      }

      .price-range {
        font-size: 14px;
        letter-spacing: 0.8px;
      }

      &:after {
        width: 30px;
        height: 30px;
        position: absolute;
        top: 4px;
        right: 0;
        content: url('~@/assets/images/down.svg');
        transition: 0.1s ease-out;
      }
    }

    &__data-row {
      font-size: 12px;
      padding: 8px 8px 8px 0;

      &:first-child {
        margin-top: 20px;
        font-size: 14px;
        font-weight: bold;
      }

      &:nth-child(odd) {
        background: linear-gradient(to right, rgba(0, 0, 0, 0) 15%, rgba(0, 0, 0, 0.1));
      }
    }

    .highlight-label {
      font-size: 10px;
      letter-spacing: 0.05em;
      margin-top: 10px;
      padding: 4px 10px 2px;

      @media screen and (max-width: 767px) {
        font-weight: 800;
        letter-spacing: 0.5px;
        padding: 4px 5px 2px;
      }
    }

    .icon-checkmark {
      background: #fdaa1c;
    }
  }
}
</style>
