import { reactive, readonly, computed } from 'vue'
import httpClient from '@/services/api/httpClient'

const state = reactive({
  galleryItems: [],
  isLoading: false
})

export default function useGallery() {
  const updateGallery = data => {
    Object.assign(state, data)
  }

  const fetchGallery = async params => {
    if (state.isLoading) return
    toggleLoading()

    try {
      const { data } = await httpClient.get('gallery', null, { ...params })

      updateGallery({ galleryItems: data.data })

      return Promise.resolve()
    } catch (error) {
      throw new Error(JSON.stringify(error))
    } finally {
      toggleLoading()
    }
  }

  const toggleLoading = () => {
    state.isLoading = !state.isLoading
  }

  const isLoading = computed(() => state.isLoading)

  const hasGalleryItems = computed(() => state.galleryItems.length)

  return {
    galleryState: readonly(state),
    fetchGallery,
    updateGallery,
    hasGalleryItems,
    isLoading,
    toggleLoading
  }
}
