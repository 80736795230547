<template>
  <div ref="carousel" class="carousel">
    <div class="carousel-inner" v-if="items.length > 0">
      <div
        v-for="(item, index) in items"
        :key="index"
        class="carousel-item"
        :class="{ active: activeItem === index }"
        :style="itemStyles(index)"
      >
        <slot :item="item" :currentIndex="index" />
      </div>
    </div>
    <div v-if="options.fullscreen" class="carousel-fullscreen" @click="close">
      <div class="fullscreen-header">
        <div class="container">
          <div class="fullscreen-header-inner d-flex align-items-center">
            <em class="icon-close"></em>
          </div>
        </div>
      </div>
      <div class="fullscreen-body">
        <div class="container position-relative">
          <div class="d-flex justify-content-center">
            <slot name="fullscreen" :item="items[activeItem]" class="mx-auto" />
          </div>
          <div class="carousel-controls">
            <span
              @click.stop.prevent="prev"
              class="carousel-control carousel-control-prev"
              :disabled="activeItem === 0"
            >
              <em class="icon-chevron-left"></em>
            </span>
            <span
              @click.stop.prevent="nextFullscreen"
              class="carousel-control carousel-control-next"
              :disabled="activeItem >= items.length - 1"
            >
              <em class="icon-chevron-right"></em>
            </span>
          </div>
        </div>
      </div>
      <div v-if="items[activeItem].tile" class="fullscreen-footer" @click.prevent.stop="">
        <div class="container">
          <slot name="fullscreen-footer" :tile="items[activeItem].tile" />
        </div>
      </div>
    </div>
    <div class="carousel-controls">
      <span @click="prev" class="carousel-control carousel-control-prev" :disabled="activeItem === 0">
        <em :class="`icon-${icon}-left`"></em>
      </span>
      <span
        @click="next"
        class="carousel-control carousel-control-next"
        :disabled="activeItem >= items.length - itemsToShow"
      >
        <em :class="`icon-${icon}-right`"></em>
      </span>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed, onBeforeMount, onUnmounted } from 'vue'

export default defineComponent({
  name: 'CarouselComponent',
  props: {
    items: {
      type: Array,
      default: () => []
    },
    options: {
      type: Object,
      default: () => {
        return {
          itemsToShow: 3,
          fullscreen: false
        }
      }
    },
    icon: {
      type: String,
      default: 'chevron'
    }
  },
  emits: ['on-close'],
  setup(props, { emit }) {
    // TODO: on resize some recalculation needed
    const carousel = ref(null)
    const activeItem = ref(0)
    const transform = ref(0)

    const itemsToShow = computed(() => {
      if (window.matchMedia('(max-width: 991px)').matches || props.options.itemsToShow <= 1) {
        return 1
      }

      return props.options.itemsToShow
    })

    const carouselSize = computed(() => {
      return carousel.value.offsetWidth
    })

    const itemSize = computed(() => {
      if (!carousel.value) {
        return 0
      }

      return carouselSize.value / itemsToShow.value
    })

    const updatePosition = () => {
      if (activeItem.value <= props.items.length - itemsToShow.value) {
        transform.value = `-${itemSize.value * activeItem.value}px`
      }
    }

    const next = () => {
      if (activeItem.value < props.items.length - itemsToShow.value) ++activeItem.value
      updatePosition()
    }

    const nextFullscreen = () => {
      if (activeItem.value < props.items.length - 1) ++activeItem.value
      updatePosition()
    }

    const prev = () => {
      if (activeItem.value > 0) --activeItem.value
      updatePosition()
    }

    const itemStyles = index => {
      return {
        left: `${itemSize.value * index}px`,
        transform: `translateX(${transform.value})`,
        width: `${itemSize.value}px`
      }
    }

    const close = () => {
      if (activeItem.value >= props.items.length - itemsToShow.value) {
        activeItem.value = props.items.length - itemsToShow.value
      }
      updatePosition()
      emit('on-close')
    }

    const handleArrowKeyPress = e => {
      if (e.key === 'ArrowLeft') {
        prev()
      }

      if (e.key === 'ArrowRight') {
        next()
      }
    }

    onBeforeMount(() => {
      document.addEventListener('keydown', handleArrowKeyPress)
    })

    onUnmounted(() => {
      document.removeEventListener('keydown', handleArrowKeyPress)
    })

    return {
      prev,
      nextFullscreen,
      next,
      activeItem,
      carousel,
      transform,
      itemStyles,
      itemsToShow,
      close
    }
  }
})
</script>

<style lang="scss" scoped>
@import '@/assets/style/base/_variables.scss';

.carousel-fullscreen {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: $black;
  z-index: 2;

  .carousel-control {
    &[disabled='true'] {
      pointer-events: auto;
      cursor: default;

      &:hover {
        opacity: 0.3;
      }
    }
  }
}

.fullscreen-header {
  background: $black;
  height: 80px;
  text-align: right;

  &-inner,
  .container {
    height: 100%;
  }

  .icon-close {
    margin-left: auto;
    cursor: pointer;
    width: 20px;
    height: 20px;
  }
}

.fullscreen-body {
  position: absolute;
  width: 100%;
  height: 100%;

  :deep(img) {
    max-width: 100vw;
    height: auto;
    width: auto;
    max-height: calc(100vh - 260px);
  }
}

.fullscreen-footer {
  height: 160px;
  display: flex;
  align-items: center;
  background: $white-two;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  @media (max-width: 576px) {
    padding: 20px 0;
  }
}

.carousel-inner {
  @media screen and (max-width: 991px) {
    max-height: 240px;
  }
}

.carousel-item {
  display: flex;
  justify-content: space-between;
  transition: 0.4s ease-out;
}

.carousel-control {
  position: absolute;
  cursor: pointer;
  z-index: 1;
  width: 60px;
  height: 60px;
  top: calc(50% - 30px);
  bottom: 0;
  opacity: 1;

  &-prev {
    @media screen and (max-width: 1199px) {
      left: 0;
    }

    @media screen and (min-width: 1200px) {
      left: auto;
      right: 100%;
    }
  }

  &-next {
    @media screen and (max-width: 1199px) {
      right: 0;
    }

    @media screen and (min-width: 1200px) {
      left: 100%;
      right: auto;
    }
  }

  &[disabled='true'] {
    opacity: 0.3;
    cursor: default;

    &:hover {
      opacity: 0.5;
    }
  }

  em {
    border-radius: 50%;
    width: 30px;
    height: 30px;
  }
}
</style>
