<template>
  <div class="form-component">
    <div class="list">
      <div v-if="data.options && data.options.length" class="d-flex flex-wrap justify-content-center">
        <div
          v-for="option in data.options"
          :key="option.id"
          @click="update(option)"
          class="list-item d-flex flex-column justify-content-between"
          :class="{
            selected: isSelected(option)
          }"
        >
          <div class="d-flex justify-content-center align-items-center">
            <img :src="option.image" :alt="option.label" />
          </div>
          <strong class="label">{{ option.label }}</strong>
        </div>
      </div>
      <SkeletonLoader v-else :items="2" :items-per-row="2" disable-computed-styles />
    </div>
    <ServerValidation field="generon_option" :server-validation="serverValidation" />
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { isEmpty } from 'lodash/lang'

import ServerValidation from '@/components/Global/ServerValidation'
import SkeletonLoader from '@/components/Global/SkeletonLoader'

export default defineComponent({
  name: 'GeneronOptionSelect',
  components: {
    ServerValidation,
    SkeletonLoader
  },
  emits: ['on-select'],
  props: {
    data: {
      type: Object,
      required: true
    },
    current: {
      type: [Object, Boolean],
      required: true
    },
    serverValidation: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props, { emit }) {
    const { t } = useI18n()

    const selectedGeneronOption = computed(() => props.current)

    const isSelected = ({ id }) => {
      return selectedGeneronOption.value.id === id
    }

    const update = generonOption => {
      if (!generonOption) return

      emit('on-select', generonOption)
    }

    return {
      isSelected,
      update,
      t,
      isEmpty
    }
  }
})
</script>

<style lang="scss" scoped>
@import '@/assets/style/base/_variables.scss';
.form-component {
  :deep(.skeleton-loader) {
    margin-left: -15px;
    margin-right: -15px;
  }

  :deep(.skeleton) {
    width: 300px;
    height: 165px;
    margin: 15px;
    border: 2px solid #ededed;
  }
}

.list {
  margin: 40px 0 80px;
}

.list-item {
  position: relative;
  height: auto;
  padding: 40px 0 20px;

  @media screen and (max-width: 991px) {
    width: 100%;
  }

  @media screen and (min-width: 992px) {
    width: 300px;
    margin: 0 30px 30px 0;
  }

  .label {
    margin-top: 20px;
  }
}
</style>
