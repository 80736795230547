<template>
  <div class="page-not-found text-center">
    <div class="container">
      <img src="@/assets/images/404.svg" alt="404" />
      <h1>{{ t('pageNotFound.title') }}</h1>
      <button @click="navigateTo({ name: 'Home' })" class="btn btn-dark">{{ t('pageNotFound.buttons.action') }}</button>
    </div>
  </div>
</template>

<script>
import useNavigation from '@/hooks/navigation'
import { useI18n } from 'vue-i18n'

export default {
  name: 'PageNotFound',
  setup() {
    const { navigateTo } = useNavigation()
    const { t } = useI18n()

    return {
      navigateTo,
      t
    }
  }
}
</script>

<style lang="scss" scoped>
.page-not-found {
  padding: 65px 0;

  @media (min-width: 992px) {
    padding: 120px 0;
  }

  h1 {
    margin: 80px auto 30px auto;

    @media (min-width: 992px) {
      width: 45%;
    }
  }
}
</style>
