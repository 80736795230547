import axios from 'axios'
import { useToast } from 'vue-toastification'

import { getCurrentLanguageFromDomain } from '@/services/language'

const currentLanguage =
  localStorage.getItem('language') || getCurrentLanguageFromDomain() || process.env.VUE_APP_DEFAULT_LOCALE || 'hu'

const api = axios.create({
  baseURL: process.env[`VUE_APP_API_BASE_URL_${currentLanguage.toUpperCase()}`],
  withCredentials: process.env.NODE_ENV !== 'development'
})

const collectionResponseHandler = async response =>
  Promise.resolve({
    data: response.data,
    raw: response
  })

const singularResponseHandler = async response =>
  Promise.resolve({
    data: response.data,
    raw: response
  })

const generalErrorHandler = error => {
  const toast = useToast()

  // TODO: get default error messages from server response
  switch (error.response.status) {
    case 401:
    case 403:
    case 400:
      toast.error('Hiba történt! Kérjük próbálja újra...')
      break
    case 500:
      toast.error('Nem sikerült kapcsolódni a szerverhez! Kérjük próbálja újra...')
      break
  }

  return Promise.reject({
    errStatus: error.response.status,
    errData: error.response.data,
    errRaw: error
  })
}

const setLanguageHeader = lang => (api.defaults.headers['language'] = lang)

export default {
  setLanguageHeader,
  request(method, resource, params, responseType) {
    return api
      .request({
        method,
        url: `${resource}`,
        params,
        responseType
      })
      .catch(generalErrorHandler)
  },
  get(resource, slug = '', params) {
    return api
      .request({
        method: 'get',
        url: slug ? `${resource}/${slug}` : `${resource}`,
        params
      })
      .then(singularResponseHandler)
      .catch(generalErrorHandler)
  },
  query(resource, params) {
    return api
      .request({
        method: 'get',
        url: `${resource}`,
        params
      })
      .then(collectionResponseHandler)
      .catch(generalErrorHandler)
  },
  post(resource, data, params) {
    return api
      .request({
        method: 'post',
        url: `${resource}`,
        data,
        params
      })
      .then(singularResponseHandler)
      .catch(generalErrorHandler)
  },
  put(resource, slug = '', data, params) {
    return api
      .request({
        method: 'put',
        url: `${resource}/${slug}`,
        data,
        params
      })
      .then(singularResponseHandler)
      .catch(generalErrorHandler)
  },
  patch(resource, slug = '', data, params) {
    return api
      .request({
        method: 'patch',
        url: `${resource}/${slug}`,
        data,
        params
      })
      .then(singularResponseHandler)
      .catch(generalErrorHandler)
  },
  delete(resource, slug = '') {
    return api
      .request({
        method: 'delete',
        url: `${resource}/${slug}`
      })
      .then(singularResponseHandler)
      .catch(generalErrorHandler)
  },
  upload(resource, slug = '', file, type, params) {
    return api
      .request({
        method: 'post',
        url: `${resource}/${slug}`,
        data: file,
        headers: {
          'Content-Type': type
        },
        params
      })
      .then(singularResponseHandler)
      .catch(generalErrorHandler)
  }
}
