<template>
  <section class="tetotepitek-banner no-print container-fluid">
    <div class="row">
      <div class="col-lg-6 order-0 order-md-0">
        <div
          class="tetotepitek-banner__content d-flex justify-content-center align-items-center align-items-lg-start flex-column h-100"
        >
          <h2 class="tetotepitek-banner__title text-center text-md-left">{{ t('banners.tetotepitek.title') }}</h2>
          <p class="tetotepitek-banner__description">{{ t('banners.tetotepitek.description') }}</p>
          <a :href="t('banners.tetotepitek.cta.url')" target="_blank" class="btn btn-dark">
            {{ t('banners.tetotepitek.cta.label') }}
          </a>
        </div>
      </div>
      <div class="col-lg-6 tetotepitek-banner__container">
        <div class="tetotepitek-banner__image text-center">
          <img
            class="tetotepitek-banner__image__checklist"
            src="@/assets/images/tetotepitek/tetotepitek-checklist.png"
            alt="tetotepitek"
          />
          <img
            class="tetotepitek-banner__image__people"
            src="@/assets/images/tetotepitek/tetotepitek-people.png"
            alt="tetotepitek"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'TetotepitekBanner',
  setup() {
    const { t } = useI18n()

    return {
      t
    }
  }
})
</script>

<style lang="scss" scoped>
@import '@/assets/style/base/_variables.scss';

.tetotepitek-banner {
  overflow: hidden;
  background: linear-gradient(166.63deg, #fdab1c -93.31%, #fb9f1e 7.17%, #e42730 328.92%);
  padding: 0;
  max-width: 375px;
  @media (min-width: 992px) {
    max-width: 100%;
  }

  @media (max-width: 767px) {
    margin-top: 65px;
  }

  &__content {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 335px;

    @media (min-width: 768px) {
      width: 80%;
      margin-left: auto;
    }

    @media (min-width: 992px) {
      width: 55%;
      margin-left: 40px;
    }

    @media (min-width: 1200px) {
      width: 430px;
      margin-left: auto;
    }
  }

  &__title {
    color: $white;
    font-family: $font-family-heading;
    font-size: 30px;
    margin: 80px 5px 0 5px;

    @media (min-width: 992px) {
      font-size: 40px;
      margin: 0 0 12px;
    }
  }

  &__description {
    color: $dark;
    font-family: $font-family-bold;
    text-align: center;
    font-size: 16px;
    margin: 10px 0 15px;

    @media (min-width: 992px) {
      margin: 0 0 70px;
      font-size: 28px;
      text-align: left;
    }
  }

  &__container {
    padding: 0;
    margin-left: auto;
    max-width: 375px;
    margin-right: auto;

    @media (min-width: 992px) {
      max-width: 776px;
      margin-right: 0;
    }
  }

  &__image {
    position: relative;
    margin: 70px 0 0 0;
    width: 100%;
    height: 287px;

    @media (min-width: 992px) {
      margin: 0;
      height: 600px;
    }

    &:before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      background-color: #4a83b4;
      background-image: url('~@/assets/images/tetotepitek/tetotepitek-bg-mobile.png');
      background-position: left;
      background-size: 150%;
      width: 100%;
      max-width: 375px;
      height: 100%;
      clip-path: polygon(0% 25%, 100% 0%, 100% 100%, 0 100%);

      @media (min-width: 992px) {
        max-width: 766px;
        background-size: cover;
        height: 100%;
        clip-path: polygon(250px 0%, 100% 0%, 100% 100%, 0 100%);
        background-image: url('~@/assets/images/tetotepitek/tetotepitek-bg-desktop.png');
      }
    }

    &__checklist {
      position: absolute;
      top: 20px;
      width: 120px;
      left: 50%;
      transform: translateX(calc(-40% - 120px));

      @media (min-width: 992px) {
        top: 80px;
        left: 180px;
        right: auto;
        width: 250px;
        height: auto;
        transform: unset;
      }
    }

    &__people {
      position: absolute;
      bottom: 0;
      width: 270px;
      left: 50%;
      transform: translateX(-40%);

      @media (min-width: 992px) {
        height: 90%;
        width: auto;
        right: auto;
        left: -200px;
        transform: unset;
      }
    }
  }
}
</style>
