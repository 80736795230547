<template>
  <a :href="banner.link" target="_blank" class="banner" @click="trackBanner({ label: banner.id })">
    <img :src="banner.image" :alt="banner.link" />
  </a>
</template>

<script>
import { defineComponent } from 'vue'
import useGtmEvent from '@/hooks/gtm'

export default defineComponent({
  name: 'DefaultBanner',
  props: {
    banner: {
      type: Object,
      required: true
    }
  },
  setup() {
    const { trackBanner } = useGtmEvent()

    return {
      trackBanner
    }
  }
})
</script>

<style lang="scss" scoped>
.banner {
  width: 245px;
  margin: 0 15px 30px;

  @media (max-width: 576px) {
    width: 100%;
  }
}
</style>
