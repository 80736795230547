<template>
  <div class="autocomplete-container">
    <input
      ref="autocompleteRef"
      type="text"
      class="autocomplete-input form-control"
      :class="{ 'is-invalid': errorState }"
      @input="handleInput"
      @focus="displayResults"
      @blur="hideResults"
      v-bind="$attrs"
      :value="typeof modelValue === 'string' ? modelValue : modelValue?.name"
      :placeholder="placeholder"
      autocapitalize="sentences"
    />
    <div class="autocomplete-results-container" v-if="shouldShowResults">
      <div
        v-for="item in filteredResults"
        :key="item"
        class="autocomplete-results-item"
        @click="selectItem(item)"
        @mousedown.prevent
      >
        {{ displayItem(item) }}
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue'

export default {
  name: 'AutocompleteComponent',
  props: {
    debounce: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 20
    },
    results: {
      type: Array,
      default: () => []
    },
    displayItem: {
      type: Function,
      default: item => (typeof item === 'string' ? item : item?.name)
    },
    placeholder: {
      type: String,
      default: ''
    },
    errorState: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: [String, Number, Object],
      default: ''
    }
  },
  emits: ['input', 'on-select', 'update:modelValue'],
  setup(props, { emit }) {
    const autocompleteRef = ref()
    const searchText = ref('')
    const showResults = ref(false)
    let timeout

    const shouldShowResults = computed(() => showResults.value && props.results.length > 0)
    const filteredResults = computed(() => props.results.slice(0, props.max))

    function handleInput(e) {
      clearTimeout(timeout)
      timeout = setTimeout(() => emit('input', e.target.value), props.debounce)
      emit('update:modelValue', e.target.value)
      showResults.value = true
    }

    function selectItem(data) {
      emit('on-select', data)
      showResults.value = false
      searchText.value = data.name
    }

    function displayResults() {
      showResults.value = true
    }

    function hideResults() {
      showResults.value = false
    }

    return {
      searchText,
      showResults,
      autocompleteRef,
      displayResults,
      hideResults,
      handleInput,
      selectItem,
      filteredResults,
      shouldShowResults
    }
  }
}
</script>

<style lang="scss" scoped>
.autocomplete-container {
  display: flex;
  flex-direction: column;
  position: relative;

  .autocomplete-results-container {
    position: absolute;
    width: 100%;
    height: auto;
    max-height: 200px;
    overflow-y: scroll;
    top: 100%;
    border: 2px solid #dfdfdf;
    z-index: 99;
    background: white;
    padding: 5px 10px;
    border-radius: 5px;
  }

  .autocomplete-results-item {
    list-style-type: none;
    padding: 12px 5px;
    border-bottom: 1px solid #dfdfdf;

    &:hover {
      cursor: pointer;
    }

    &:nth-last-child(1) {
      border-bottom: none;
    }
  }
}
</style>
