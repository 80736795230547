<template>
  <footer class="no-print">
    <ul>
      <li>
        <a :href="t('footer.links.privacyPolicy.url')" target="_blank">{{ t('footer.links.privacyPolicy.label') }}</a>
      </li>
      <li>
        <a :href="t('footer.links.cookie.url')" target="_blank">{{ t('footer.links.cookie.label') }}</a>
      </li>
    </ul>
    <p class="copyright">Copyright © {{ currentYear }} {{ t('footer.copyright') }}</p>
    <div class="site-footer__credits site-footer__credits--logo">
      <YcoLogo />
    </div>
  </footer>
</template>

<script>
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

import YcoLogo from '@/components/Global/YcoLogo'

export default defineComponent({
  name: 'AppFooter',
  components: {
    YcoLogo
  },
  setup() {
    const { t } = useI18n()

    const currentYear = new Date().getFullYear()

    return {
      currentYear,
      t
    }
  }
})
</script>

<style lang="scss" scoped>
@import '@/assets/style/base/_variables.scss';

footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: $white-two;

  @media screen and (max-width: 991px) {
    padding: 60px 0;
  }

  @media screen and (min-width: 992px) {
    padding: 90px 0 75px;
  }

  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style-type: none;
    padding-left: 0;
    margin: 0;

    @media screen and (max-width: 991px) {
      flex-direction: column;
    }
  }

  li {
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 800;
    letter-spacing: 1px;

    @media screen and (max-width: 991px) {
      &:not(:last-of-type) {
        margin-bottom: 30px;
      }

      &:last-of-type {
        margin-bottom: 5px;
      }
    }

    @media screen and (min-width: 992px) {
      &:not(:last-of-type) {
        padding-right: 20px;
        margin-right: 20px;
        border-right: 2px solid $white;
      }
    }

    a,
    a:hover,
    a:active,
    a:focus {
      color: $dark;
    }
  }

  .copyright {
    font-family: 'OpenSans', sans-serif;
    font-weight: 600;

    @media screen and (max-width: 991px) {
      margin: 30px 0;
      font-size: 10px;
    }

    @media screen and (min-width: 992px) {
      margin: 40px 0;
      font-size: 12px;
    }
  }
}
</style>
