import { ref } from 'vue'

import { isEmpty } from '@/utils/helpers'

export default function useFormControl() {
  const serverValidation = ref({})

  const hasServerValidationError = field => !isEmpty(serverValidation.value) && serverValidation.value[field]

  return {
    serverValidation,
    hasServerValidationError
  }
}
