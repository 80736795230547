<template>
  <div class="form-wizard-step">
    <div class="form-wizard-content text-center">
      <div class="container">
        <RoofTypeSelect :roof-types="roofTypes" :current="roofType" @on-select="setRoofType" />
        <ServerValidation field="roof_type" :server-validation="serverValidation" />

        <DegreeSelect @on-select="setDegree" :current="degree" />
        <ServerValidation field="degree" :server-validation="serverValidation" />
      </div>
    </div>

    <div class="form-wizard-footer">
      <div class="form-wizard-actions container">
        <div class="row">
          <div class="col-md-6 offset-md-3">
            <button
              data-test="next-step"
              @click="validateStep"
              :disabled="isLastStep || isLoading"
              class="btn btn-primary btn-block"
            >
              {{ !isLoading ? t('steps.roofType.buttons.next') : t('general.loading') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onBeforeMount, reactive, toRefs, onUnmounted } from 'vue'
import { useToast } from 'vue-toastification'
import { diff } from 'deep-object-diff'
import { isEmpty } from 'lodash/lang'
import { useVuelidate } from '@vuelidate/core'
import { useI18n } from 'vue-i18n'

import httpClient from '@/services/api/httpClient'
import useFormWizard from '@/hooks/formWizard'
import useFormState from '@/hooks/formState'
import useFormControl from '@/hooks/formControl'
import useScroll from '@/hooks/scroll'

import { LANGUAGE_CONFIG } from '@/services/language'

import RoofTypeSelect from '@/components/Calculator/RoofTypeSelect.vue'
import DegreeSelect from '@/components/Calculator/DegreeSelect.vue'
import ServerValidation from '@/components/Global/ServerValidation.vue'

export default defineComponent({
  name: 'RoofType',
  components: {
    RoofTypeSelect,
    DegreeSelect,
    ServerValidation
  },
  setup() {
    const { nextStep, isLastStep } = useFormWizard()
    const { updateFormState, getFieldValue } = useFormState()
    const { serverValidation } = useFormControl()
    const toast = useToast()
    const { scrollToElement } = useScroll()
    const { t } = useI18n()
    const currentLanguage = localStorage.getItem('language') || process.env.VUE_APP_DEFAULT_LOCALE
    const DEFAULT_ROOF_DEGREE = LANGUAGE_CONFIG[currentLanguage].minDegree

    const roofTypes = ref([])
    const isLoading = ref(false)

    const state = reactive({
      roofType: getFieldValue('roofType') || false,
      degree: getFieldValue('degree') || DEFAULT_ROOF_DEGREE,
      hasModifiedDegree: getFieldValue('degree') || false
    })

    const validations = useVuelidate()

    onBeforeMount(() => {
      fetchStepData()
      document.addEventListener('keypress', submitHandler)
    })

    onUnmounted(() => {
      document.removeEventListener('keypress', submitHandler)
    })

    const submitHandler = e => e.key === 'Enter' && validateStep()

    const fetchStepData = async () => {
      try {
        isLoading.value = true

        const { data } = await httpClient.get('steps/roof_types')

        roofTypes.value = data.data

        if (!getFieldValue('roofType') && roofTypes.value.length) {
          setRoofType(roofTypes.value[0])
        }
      } catch (error) {
        toast.error(t('errors.data'))
      } finally {
        isLoading.value = false
      }
    }

    const setRoofType = roofType => {
      state.roofType = roofType
    }

    const setDegree = degree => {
      state.hasModifiedDegree = true
      state.degree = degree
    }

    const validateStep = async () => {
      if (isLoading.value) return

      validations.value.$touch()

      if (validations.value.$invalid) return

      serverValidation.value = {}

      if (!state.hasModifiedDegree) {
        scrollToElement(document.querySelector('.degree'))
        state.hasModifiedDegree = true
        return
      }

      try {
        const stored = {
          roofType: getFieldValue('roofType') || null,
          degree: getFieldValue('degree') || null
        }

        if (stored.roofType && stored.degree && isEmpty(diff(stored, state))) {
          nextStep({ label: 'nem valtozott' })
          return Promise.resolve()
        }

        isLoading.value = true

        const data = {
          roof_type: state.roofType.id,
          degree: state.degree
        }

        await httpClient.post('validate_steps/roof_type', data)

        updateFormState({ ...state })

        nextStep({ label: state.roofType.title })

        return Promise.resolve()
      } catch (error) {
        if (error.errStatus === 422 || error.errStatus === 404) {
          serverValidation.value = Object.assign({}, error.errData.data.params)
        } else {
          toast.error(t('errors.save'))
        }
      } finally {
        isLoading.value = false
      }
    }

    return {
      nextStep,
      isLastStep,
      roofTypes,
      ...toRefs(state),
      setRoofType,
      setDegree,
      validateStep,
      isLoading,
      serverValidation,
      t
    }
  }
})
</script>
