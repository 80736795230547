import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import { createHead } from '@vueuse/head'

import Toast, { POSITION } from 'vue-toastification'
import 'vue-toastification/dist/index.css'

import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import gtm from '@/plugins/gtm'
import { setLanguageFromDomain } from '@/services/language'
import httpClient from '@/services/api/httpClient'
import * as messages from '@/i18n'

setLanguageFromDomain()

const currentLanguage = localStorage.getItem('language') || process.env.VUE_APP_DEFAULT_LOCALE
httpClient.setLanguageHeader(currentLanguage)

const i18n = createI18n({
  legacy: false,
  locale: currentLanguage,
  fallbackLocale: process.env.VUE_APP_DEFAULT_LOCALE,
  messages: JSON.parse(JSON.stringify(messages))
})

const head = createHead()

const app = createApp(App)

app.config.globalProperties.$filters = {
  price(value) {
    return new Intl.NumberFormat().format(Number(value))
  }
}

app.use(store).use(router).use(gtm).use(i18n).use(Toast, { position: POSITION.BOTTOM_RIGHT }).use(head).mount('#app')
