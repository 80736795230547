<template>
  <div class="form-wizard-step">
    <div class="form-wizard-content text-center">
      <section>
        <div class="container-lg">
          <TileShapeSelect
            :tile-shapes="tileShapes"
            :current="tileShape"
            :server-validation="serverValidation"
            @on-select="setTileShape"
          />
        </div>
      </section>
    </div>

    <div class="form-wizard-footer">
      <div class="form-wizard-actions container">
        <div class="row">
          <div class="col-md-6 order-1 order-md-0">
            <button
              data-test="previous-step"
              @click="prevStep"
              :disabled="isLoading"
              class="btn btn-secondary btn-block"
            >
              {{ t('steps.tileShape.buttons.prev') }}
            </button>
          </div>
          <div class="col-md-6">
            <button
              data-test="next-step"
              @click="validateStep"
              :disabled="isLastStep || isLoading || galleryIsLoading"
              class="btn btn-primary btn-block"
            >
              {{ isLoading || galleryIsLoading ? t('general.loading') : t('steps.tileShape.buttons.next') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onBeforeMount, onUnmounted, reactive, toRefs, computed } from 'vue'
import { useToast } from 'vue-toastification'
import { isEmpty } from 'lodash/lang'
import { diff } from 'deep-object-diff'
import { useI18n } from 'vue-i18n'

import httpClient from '@/services/api/httpClient'
import useFormWizard from '@/hooks/formWizard'
import useFormState from '@/hooks/formState'
import useGallery from '@/hooks/gallery'
import useFormControl from '@/hooks/formControl'

import TileShapeSelect from '@/components/Calculator/TileShapeSelect'

export default defineComponent({
  name: 'TileShape',
  components: {
    TileShapeSelect
  },
  setup() {
    const { nextStep, prevStep, isLastStep, goToStep, currentStepNumber, steps } = useFormWizard()
    const { updateFormState, getFieldValue } = useFormState()
    const { hasGalleryItems, fetchGallery, galleryState } = useGallery()
    const { serverValidation } = useFormControl()
    const toast = useToast()
    const { t } = useI18n()
    const currentLanguage = localStorage.getItem('language') || process.env.VUE_APP_DEFAULT_LOCALE

    const isLoading = ref(false)
    const tileShapes = ref([])
    const degree = ref(getFieldValue('degree'))

    const galleryIsLoading = computed(() => galleryState.isLoading)

    const state = reactive({
      tileShape: getFieldValue('tileShape') || false
    })

    onBeforeMount(() => {
      fetchStepData()
      if (!hasGalleryItems) fetchGallery()
      document.addEventListener('keypress', submitHandler)
    })

    onUnmounted(() => {
      document.removeEventListener('keypress', submitHandler)
    })

    const submitHandler = e => e.key === 'Enter' && validateStep()

    const isTileShapeDisabled = tileShape => {
      return currentLanguage === 'sk' && degree.value < 20 && tileShape.id === 1
    }

    const getTileShapeById = id => tileShapes.value.find(tileShape => tileShape.id === id)

    const fetchStepData = async () => {
      try {
        const { data } = await httpClient.get('steps/tile_shapes')

        tileShapes.value = data.data

        if (!getFieldValue('tileShape') && tileShapes.value.length) {
          const FLAT_TILE_SHAPE = getTileShapeById(1)
          const CORRUGATED_TILE_SHAPE = getTileShapeById(2)
          const activeTileShape = isTileShapeDisabled(FLAT_TILE_SHAPE) ? CORRUGATED_TILE_SHAPE : FLAT_TILE_SHAPE

          setTileShape(activeTileShape)
        }
      } catch (error) {
        toast.error(t('errors.data'))
      }
    }

    async function possibleNextStep(args) {
      const FLAT_TILE_SHPAE_ID = 1
      const NOT_INTERESTED_GENERON_ID = 2
      const isFlatTileSelected = state.tileShape.id === FLAT_TILE_SHPAE_ID
      const isGeneronNextStep = steps.value[currentStepNumber.value + 1] === 'Generon'

      if (isFlatTileSelected || !isGeneronNextStep) {
        nextStep(args)
        return Promise.resolve()
      }

      const stored = {
        generonOption: getFieldValue('generonOption') || false
      }

      if (stored.generonOption && stored.generonOption.id === NOT_INTERESTED_GENERON_ID) {
        goToStep(currentStepNumber.value + 2)
        return Promise.resolve()
      }

      try {
        await httpClient.post('validate_steps/generon', {
          generon_option: NOT_INTERESTED_GENERON_ID
        })

        updateFormState({ generonOption: { id: NOT_INTERESTED_GENERON_ID, interested: false } })

        goToStep(currentStepNumber.value + 2)

        return Promise.resolve()
      } catch (error) {
        return Promise.reject(error)
      }
    }

    const validateStep = async () => {
      if (isLoading.value) return

      serverValidation.value = {}

      try {
        const stored = {
          tileShape: getFieldValue('tileShape') || false
        }

        if (stored.tileShape && isEmpty(diff(stored, state))) {
          await possibleNextStep({ label: 'nem valtozott' })
          return Promise.resolve()
        }

        isLoading.value = true

        const data = {
          tile_shape: state.tileShape.id
        }

        await httpClient.post('validate_steps/tile_shape', data)

        updateFormState({ tileShape: state.tileShape })

        await possibleNextStep({ label: state.tileShape.title })

        return Promise.resolve()
      } catch (error) {
        if (error.errStatus === 422 || error.errStatus === 404) {
          serverValidation.value = Object.assign({}, error.errData.data.params)
        } else {
          toast.error(t('errors.save'))
        }
      } finally {
        isLoading.value = false
      }
    }

    const setTileShape = async tileShape => {
      if (state.tileShape.id !== tileShape.id) {
        await fetchGallery({ tile_shape: tileShape.id })
      }

      state.tileShape = tileShape
    }

    return {
      nextStep,
      prevStep,
      isLastStep,
      tileShapes,
      setTileShape,
      validateStep,
      ...toRefs(state),
      serverValidation,
      isLoading,
      galleryIsLoading,
      t
    }
  }
})
</script>

<style lang="scss" scoped>
@import '@/assets/style/base/_variables.scss';

.gallery-container {
  background-image: $gradient-gray;
}
</style>
