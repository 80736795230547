<template>
  <div class="form-component">
    <h2>{{ t('steps.tileShape.title') }}</h2>
    <p>{{ t('steps.tileShape.description') }}</p>

    <div class="tile-shapes list">
      <div v-if="tileShapes.length" class="d-flex flex-wrap justify-content-center">
        <div
          v-for="tileShape in tileShapes"
          :key="tileShape.id"
          @click="update(tileShape)"
          class="tile-shapes list-item d-flex flex-column justify-content-between"
          :class="{
            selected: isSelected(tileShape),
            loading: galleryIsLoading,
            disabled: isDisabled(tileShape)
          }"
        >
          <ul v-if="tileShape.badges && currentLanguage !== 'ro'" class="badges">
            <li v-for="(badge, index) in tileShape.badges" :key="index" class="badge">
              <Tooltip :tooltip-text="badge.title" position="left" :force-open="showTooltip">
                <img :src="badge.image" :alt="badge.title" />
              </Tooltip>
            </li>
          </ul>
          <div class="tile-image d-flex justify-content-center align-items-center">
            <img :src="tileShape.image" :alt="tileShape.title" />
          </div>
          <strong class="label">{{ tileShape.title }}</strong>
          <small class="pt-1" v-if="isDisabled(tileShape)">
            {{ t('steps.tileShape.errors.degree') }}
          </small>
        </div>
      </div>
      <SkeletonLoader v-else :items="2" :items-per-row="2" disable-computed-styles />
    </div>
    <ServerValidation field="tile_shape" :server-validation="serverValidation" />
  </div>
</template>

<script>
import { defineComponent, computed, ref, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'

import ServerValidation from '@/components/Global/ServerValidation'
import SkeletonLoader from '@/components/Global/SkeletonLoader'
import Tooltip from '@/components/Global/Tooltip'

import useGallery from '@/hooks/gallery'
import useFormState from '@/hooks/formState'

export default defineComponent({
  name: 'TileShapeSelect',
  components: {
    ServerValidation,
    SkeletonLoader,
    Tooltip
  },
  emits: ['on-select'],
  props: {
    tileShapes: {
      type: Array,
      required: true
    },
    current: {
      type: [Object, Boolean],
      required: true
    },
    serverValidation: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props, { emit }) {
    const { galleryState } = useGallery()
    const { t } = useI18n()
    const { getFieldValue } = useFormState()
    const currentLanguage = localStorage.getItem('language') || process.env.VUE_APP_DEFAULT_LOCALE

    const showTooltip = ref(true)
    const degree = ref(getFieldValue('degree'))

    const selectedTileShape = computed(() => props.current)
    const galleryIsLoading = computed(() => galleryState.isLoading)

    const isDisabled = tileShape => {
      return currentLanguage === 'sk' && degree.value < 20 && tileShape.id === 1
    }

    const isSelected = ({ id }) => {
      return selectedTileShape.value.id === id
    }

    const update = tileShape => {
      if (!tileShape) return

      emit('on-select', tileShape)
    }

    onMounted(() => {
      setTimeout(() => (showTooltip.value = false), 3500)
    })

    return {
      isSelected,
      isDisabled,
      update,
      showTooltip,
      galleryIsLoading,
      t,
      degree,
      currentLanguage
    }
  }
})
</script>

<style lang="scss" scoped>
@import '@/assets/style/base/_variables.scss';

h2 {
  margin-top: 60px;
}

.form-component {
  :deep(.skeleton-loader) {
    margin-left: -15px;
    margin-right: -15px;
  }

  :deep(.skeleton) {
    width: 300px;
    height: 165px;
    margin: 15px;
    border: 2px solid #ededed;
  }
}

.tile-shapes {
  &.list {
    margin: 40px 0 80px;
  }

  .list-item {
    position: relative;
    height: auto;
    padding: 40px 0 20px;

    @media screen and (max-width: 991px) {
      width: 100%;
    }

    @media screen and (min-width: 992px) {
      width: 300px;
      margin: 0 30px 30px 0;
    }

    .badge {
      position: absolute;
      top: 15px;
      right: 15px;
      display: flex;
      flex-direction: column;

      img:not(:first-of-type) {
        margin-top: 15px;
      }
    }

    .label {
      margin-top: 20px;
    }

    .tile-image {
      height: 100%;

      img {
        height: auto;
        width: 100%;
      }
    }
  }
}
</style>
