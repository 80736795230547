<template>
  <div class="modal__mask">
    <div class="modal__wrapper">
      <div class="modal__container">
        <button class="modal__close" @click="$emit('close')">
          <em class="icon-close icon-close--black"></em>
        </button>

        <div class="modal__header">
          <slot name="header"></slot>
        </div>

        <div class="modal__body">
          <slot name="body"></slot>
        </div>

        <div class="modal__footer">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ModalComponent'
})
</script>

<style lang="scss">
.modal__mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: table;
  transition: opacity 0.3s ease;
}

.modal__wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal__header {
  h3 {
    font-size: 22px;
  }
}

.modal__container {
  max-width: 500px;
  margin: 0px auto;
  padding: 60px 50px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  position: relative;
}

.modal__close {
  background-color: transparent;
  border: none;
  position: absolute;
  top: 20px;
  right: 20px;

  em {
    width: 20px;
    height: 20px;
  }
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal__container,
.modal-leave-active .modal__container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
