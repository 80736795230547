export function isEmpty(value) {
  return (
    value === undefined ||
    value === null ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0)
  )
}

export function roundToHalf(initialNum) {
  let roundedNum = Math.floor(initialNum)

  if (initialNum - roundedNum < 0.5) {
    return roundedNum
  }

  return roundedNum + 0.5
}
