import { reactive, computed, toRefs, watch } from 'vue'
import useGtmEvent from '@/hooks/gtm'
import useScroll from '@/hooks/scroll'

const state = reactive({
  currentStepNumber: 0,
  currentStepComponent: null,
  steps: []
})

export default function useFormWizard() {
  const setSteps = steps => (state.steps = steps)
  const { trackStep } = useGtmEvent()
  const gtmEvent = {
    category: `step${state.currentStepNumber + 1}`
  }
  const { scrollToTop } = useScroll()

  const maxSteps = computed(() => state.steps.length - 1)
  const isLastStep = computed(() => state.currentStepNumber === maxSteps.value)
  const inProgress = computed(() => state.currentStepNumber <= maxSteps.value)
  const currentStep = computed(() => state.steps[state.currentStepNumber])
  const currentProgress = computed(() => (state.currentStepNumber + 1) / state.steps.length)

  watch(currentStep, () => {
    maybeShowTidioChat()
  })

  const maybeShowTidioChat = () => {
    const currentLanguage = localStorage.getItem('language') || process.env.VUE_APP_DEFAULT_LOCALE
    const TIDIO_CONTAINER = document.querySelector('#tidio-chat')
    const hasTidioScript = document.querySelector('#tidio-script')

    const tidioScript = document.createElement('script')
    tidioScript.src = `//code.tidio.co/v1ysmwol26aoumnzprovppprxb7siwys.js`
    tidioScript.id = 'tidio-script'
    tidioScript.async = false

    const showTidioChat = () => TIDIO_CONTAINER && (TIDIO_CONTAINER.style.display = 'block')

    if (currentLanguage === 'hu') {
      if (!hasTidioScript) {
        document.body.insertAdjacentElement('beforeend', tidioScript)
      }

      showTidioChat()
    }
  }

  const prevStep = () => {
    Object.assign(gtmEvent, {
      action: 'vissza'
    })

    trackStep(gtmEvent)

    state.currentStepNumber--
    scrollToTop()
  }

  const nextStep = args => {
    Object.assign(gtmEvent, {
      action: 'tovabb'
    })

    if (args) {
      Object.assign(gtmEvent, args)
    }

    trackStep(gtmEvent)

    state.currentStepNumber++
    scrollToTop()
  }

  const goToStep = step => {
    Object.assign(gtmEvent, {
      action: state.currentStepNumber > step ? 'hatra' : 'elore',
      label: `target${step}`
    })

    trackStep(gtmEvent)

    state.currentStepNumber = parseInt(step, 10)
    scrollToTop()
  }

  const resetFormWizard = () => {
    state.currentStepNumber = 0
  }

  return {
    ...toRefs(state),
    isLastStep,
    currentProgress,
    inProgress,
    currentStep,
    prevStep,
    nextStep,
    goToStep,
    setSteps,
    resetFormWizard,
    maxSteps
  }
}
