<template>
  <div class="validation-errors" v-if="field.$error">
    <p class="error" v-for="$error in field.$errors" :key="$error.$property">
      {{ getValidationMessage($error.$validator) }}
    </p>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'ErrorMessage',
  props: {
    field: {
      type: Object,
      required: true
    }
  },
  setup() {
    const { t } = useI18n()

    function getValidationMessage(validator) {
      const errorMessage = t(`validations.${[validator.toUpperCase()]}`)

      if (!errorMessage) {
        return t('validations.default')
      }

      return errorMessage
    }

    return {
      getValidationMessage
    }
  }
})
</script>

<style lang="scss" scoped>
@import '@/assets/style/base/_variables.scss';

.validation-errors {
  .error {
    font-weight: bold;
    font-size: 14px;
    font-family: $font-family-base;
    color: #e45043;
    margin: 5px 0 5px 5px;
    text-align: left;
  }
}
</style>
