<template>
  <a href="https://y-collective.com/" target="_blank" rel="noreferrer noopener" class="site-footer__credits__link">
    <span class="site-footer__hexagon">
      <svg
        width="49px"
        height="56px"
        viewBox="0 0 49 56"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        class="site-footer__hexagon__child"
      >
        <path
          d="M44.8461325,11.2156271 L28.0405248,1.39383486 C24.8638037,-0.396037414 24.1984996,-0.457575262 21.2654654,1.1831996 C17.5851948,3.32893645 6.52187824,9.76157565 4.54987774,10.9111027 C0.644908863,13.1911678 0.0826355414,13.5575818 0,16.895043 L0,37.9255148 C0.036218982,41.3093931 0.252829592,42.1294289 2.45023171,43.5433928 C2.45023171,43.5433928 18.914748,53.1352093 21.3723641,54.5829312 C24.1781045,56.1431794 24.8863086,56.0805866 27.9315163,54.3656147 L45.5367546,44.0852775 C48.5035464,42.3224819 48.8207262,41.741213 48.8657362,38.1568971 C48.863978,35.2030803 48.8899994,17.8863298 48.8899994,17.8863298 C48.8741756,13.7843927 48.6118517,13.4186821 44.8461325,11.2156271 Z"
        ></path>
      </svg>
      <svg
        width="49px"
        height="56px"
        viewBox="0 0 49 56"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        class="site-footer__hexagon__child"
      >
        <path
          d="M44.8461325,11.2156271 L28.0405248,1.39383486 C24.8638037,-0.396037414 24.1984996,-0.457575262 21.2654654,1.1831996 C17.5851948,3.32893645 6.52187824,9.76157565 4.54987774,10.9111027 C0.644908863,13.1911678 0.0826355414,13.5575818 0,16.895043 L0,37.9255148 C0.036218982,41.3093931 0.252829592,42.1294289 2.45023171,43.5433928 C2.45023171,43.5433928 18.914748,53.1352093 21.3723641,54.5829312 C24.1781045,56.1431794 24.8863086,56.0805866 27.9315163,54.3656147 L45.5367546,44.0852775 C48.5035464,42.3224819 48.8207262,41.741213 48.8657362,38.1568971 C48.863978,35.2030803 48.8899994,17.8863298 48.8899994,17.8863298 C48.8741756,13.7843927 48.6118517,13.4186821 44.8461325,11.2156271 Z"
        ></path>
      </svg>
      <svg
        width="49px"
        height="56px"
        viewBox="0 0 49 56"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        class="site-footer__hexagon__child"
      >
        <path
          d="M44.8461325,11.2156271 L28.0405248,1.39383486 C24.8638037,-0.396037414 24.1984996,-0.457575262 21.2654654,1.1831996 C17.5851948,3.32893645 6.52187824,9.76157565 4.54987774,10.9111027 C0.644908863,13.1911678 0.0826355414,13.5575818 0,16.895043 L0,37.9255148 C0.036218982,41.3093931 0.252829592,42.1294289 2.45023171,43.5433928 C2.45023171,43.5433928 18.914748,53.1352093 21.3723641,54.5829312 C24.1781045,56.1431794 24.8863086,56.0805866 27.9315163,54.3656147 L45.5367546,44.0852775 C48.5035464,42.3224819 48.8207262,41.741213 48.8657362,38.1568971 C48.863978,35.2030803 48.8899994,17.8863298 48.8899994,17.8863298 C48.8741756,13.7843927 48.6118517,13.4186821 44.8461325,11.2156271 Z"
        ></path>
      </svg>
    </span>
  </a>
</template>

<style lang="scss" scoped>
$white: #ffffff;
$white-two: #ededed;
$dark: #222222;

.site-footer__credits {
  margin-bottom: -15px;
}

.site-footer__hexagon {
  bottom: 0;
  height: 0;
  margin: auto;
  position: absolute;
  right: 15px;
  top: 0;
  width: 0;
}

.site-footer__hexagon__child {
  height: 11px;
  position: absolute;
  width: 10px;

  &:nth-child(1) {
    left: -99px;
    margin: auto;
    right: -100px;
    top: -12px;
  }

  &:nth-child(2) {
    left: -10px;
    top: -2px;
  }

  &:nth-child(3) {
    left: 1px;
    top: -2px;
  }

  path {
    transition: 0.3s ease-out;
    fill: $dark;
  }
}

.site-footer__credits__link {
  display: inline-block;
  height: 30px;
  position: relative;
  width: 30px;

  @media screen and (min-width: 992px) {
    &:hover {
      .site-footer__hexagon__child {
        animation: footer-hexagon 1s cubic-bezier(0.19, 1.6, 0.4, 1);

        &:nth-child(1) {
          path {
            transition-delay: 0.3s;
            fill: #ff817c;
          }
        }

        &:nth-child(2) {
          animation-delay: 0.1s;

          path {
            transition-delay: 0.4s;
            fill: #86dfcf;
          }
        }

        &:nth-child(3) {
          animation-delay: 0.2s;

          path {
            transition-delay: 0.5s;
            fill: #90c9f3;
          }
        }
      }
    }
  }
}
</style>
