<template>
  <div class="generon-price-section">
    <div class="row print-row">
      <div class="col-lg-6 print-roof">
        <RoofPriceBanner />
      </div>
      <div class="col-lg-6 px-0 px-sm-3 print-generon">
        <GeneronCalculator />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

// import MaterialRequirementsBanner from '@/components/Banners/MaterialRequirementsBanner.vue'
import RoofPriceBanner from '@/components/Banners/RoofPriceBanner.vue'
import GeneronCalculator from '@/components/Calculator/GeneronCalculator.vue'

export default defineComponent({
  name: 'GeneronRoofPriceBanner',
  components: {
    // MaterialRequirementsBanner,
    RoofPriceBanner,
    GeneronCalculator
  }
})
</script>

<style lang="scss" scoped>
@import '@/assets/style/base/_variables.scss';

.generon-price-section {
  margin-top: 60px;

  @media (min-width: $screen-md-min) {
    margin-top: 120px;
  }

  :deep(.price-section),
  :deep(.material-requirements-banner) {
    .btn-dark {
      background-color: $dark-blue-gray;

      &:hover,
      &:focus,
      &:active {
        background-color: $dark-blue-gray;
        border-color: $dark-blue-gray;
      }
    }
  }

  :deep(.price-section) {
    .banner {
      margin-top: 0;
      margin-bottom: 30px;

      &__content {
        color: $dark-blue-gray;

        @media (max-width: $screen-md-max) {
          border: 0;
          text-align: left !important;
          padding: 0;
        }
      }
    }
  }

  :deep(.material-requirements-banner) {
    margin: 0;

    @media (max-width: $screen-md-max) {
      margin: 30px 0;
    }

    .banner {
      &__content {
        background-color: $white;
        border-width: 0 2px 2px 2px;
        border-style: solid;
        border-color: $white-two;
        margin-left: 0;
        padding: 20px 30px 30px;
        color: $dark-blue-gray;

        @media (max-width: $screen-md-max) {
          border: 0;
          text-align: left !important;
          padding: 0;
          align-items: flex-start !important;
        }
      }

      &__image {
        display: none;
      }
    }
  }

  :deep(.generon-calculator) {
    height: 100%;
  }
}
</style>
