import { useRouter } from 'vue-router'

export default function useNavigation() {
  const router = useRouter()

  async function navigateTo(route) {
    await router.push(route)
  }

  return {
    navigateTo
  }
}
