<template>
  <div class="questions">
    <div v-for="question in filteredQuestions" :key="question.id" class="question">
      <h3 class="question-title">{{ question.title }}</h3>
      <p v-if="question.description">{{ question.description }}</p>
      <div class="question-list">
        <label
          v-for="option in question.options"
          :key="option.id"
          :class="`question-option--${question.type}`"
          class="question-option"
        >
          <img :alt="option.label" :src="requireIfExists(question, option)" />
          <input
            v-model="validations[question.id].$model"
            :name="question.id"
            :type="question.type"
            :value="option.id"
            @change="updateValidator(question)"
          />
          <span class="field-decorator"></span>
          {{ option.label }}
        </label>
        <ErrorMessage :field="validations[question.id]" />
      </div>
    </div>
    <ServerValidation :server-validation="serverValidation" field="questions" />
  </div>
</template>

<script>
import { computed, defineComponent, reactive, ref, toRefs } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { requiredIf } from '@vuelidate/validators'

import ErrorMessage from '@/components/Global/ErrorMessage.vue'
import ServerValidation from '@/components/Global/ServerValidation'

export default defineComponent({
  components: {
    ErrorMessage,
    ServerValidation
  },
  props: {
    questions: {
      type: Array,
      required: true
    },
    current: {
      type: Object,
      required: true
    },
    serverValidation: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    requireIfExists(question, option) {
      try {
        return require(`@/assets/images/questions/${question.id}-${option.id}.svg`)
      } catch (e) {
        return require(`@/assets/images/questions/1-1.svg`)
      }
    }
  },
  setup(props) {
    const state = reactive({
      validations: null
    })

    const localQuestions = ref(props.questions)

    const filteredQuestions = computed(() =>
      localQuestions.value.filter(
        question =>
          !question.dependency ||
          question.dependency.optionId === state.validations[question.dependency.questionId].$model
      )
    )

    const currentData = computed(() => props.current)

    const rules = computed(() => {
      const ruleSet = {}
      props.questions.forEach(question => {
        ruleSet[question.id] = {
          requiredIf: requiredIf(() => {
            if (!question.dependency) {
              return question.required
            }

            if (question.dependency.optionId === currentData.value[question.dependency.questionId]) {
              return question.required
            }

            return false
          })
        }
      })
      return ruleSet
    })

    state.validations = useVuelidate(rules.value, currentData)

    const updateValidator = question => {
      props.questions.forEach(item => {
        if (item.dependency && item.dependency.questionId === question.id) {
          state.validations[item.id].$reset()
        }
      })
    }

    return {
      currentData,
      ...toRefs(state),
      localQuestions,
      filteredQuestions,
      updateValidator
    }
  }
})
</script>

<style lang="scss" scoped>
@import '@/assets/style/base/_variables.scss';

.questions {
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  font-size: 16px;
}

.question {
  &:not(:first-child) {
    margin-top: 20px;
    padding-top: 20px;
  }

  &-title {
    font-family: $font-family-heading-extrabold;
    font-size: 30px;
    text-align: center;
    margin-bottom: 36px;
  }

  &-option {
    position: relative;
    cursor: pointer;
    height: 128px;
    width: 300px;
    padding: 35px 0 17px;
    text-transform: uppercase;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 40px;
    }

    .field-decorator {
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
      top: 0;

      &:before,
      &:after {
        content: '';
        display: block;
        position: absolute;
        transition: 0.1s ease-out;
      }

      &:before {
        border: 4px solid #ededed;
        width: 100%;
        height: 100%;
        left: 0;
      }
    }

    &--radio {
      .field-decorator {
        &:before,
        &:after {
          border-radius: 0;
        }
      }
    }
  }
}

.question-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
}

[type='radio'],
[type='checkbox'] {
  opacity: 0;
  visibility: hidden;
  display: inline-block;
  width: 18px;
  margin-right: 6px;

  &:checked + .field-decorator {
    &:before {
      border-color: $yellowish-orange;
    }

    &:after {
      transform: scale(1);
    }
  }
}
</style>
