<template>
  <section class="material-requirements-banner no-print">
    <div class="banner d-flex flex-column flex-md-row">
      <img
        class="banner__image"
        src="@/assets/images/summary/material-req-banner.png"
        alt="material-requirements-banner"
      />
      <div
        class="banner__content d-flex justify-content-center align-items-center align-items-md-start flex-column text-center text-md-left"
      >
        <h2 class="banner__title">
          {{ t('banners.materialRequirements.title') }}
        </h2>
        <p class="banner__text">
          {{ t('banners.materialRequirements.description') }}
        </p>
        <a :href="t('banners.materialRequirements.url')" target="_blank" class="btn btn-dark" @click="track">
          {{ t('banners.materialRequirements.buttons.action') }}
        </a>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from 'vue'
import useGtmEvent from '@/hooks/gtm'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'MaterialRequirementsBanner',
  setup() {
    const { trackEvent } = useGtmEvent()
    const { t } = useI18n()

    const track = () => {
      trackEvent({
        category: 'anyagszukseglet',
        action: 'kattintas'
      })
    }

    return {
      track,
      t
    }
  }
})
</script>

<style lang="scss" scoped>
@import '@/assets/style/base/_variables.scss';

.material-requirements-banner {
  margin-top: 65px;

  @media (min-width: 768px) {
    margin: 75px 0;
  }
}

.banner {
  &__title {
    font-size: 26px;
  }

  &__text {
    margin-bottom: 25px;
  }

  &__image {
    @media (min-width: 768px) {
      max-width: 40%;
    }
  }

  &__content {
    background-color: $white-two;
    padding: 10px 20px 60px;

    @media (min-width: 768px) {
      margin-left: -2px;
      padding: 20px 30px;
    }
  }
}
</style>
