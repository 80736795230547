<template>
  <section class="banner envirosense-banner no-print">
    <div class="banner__content">
      <div class="d-flex flex-column justify-content-between text-center text-md-left">
        <div>
          <h2>{{ $t('banners.envirosense.title') }}</h2>
          <p class="banner__text">{{ $t('banners.envirosense.description') }}</p>
          <p class="banner__text">{{ $t('banners.envirosense.otherDescription') }}</p>
        </div>
        <div>
          <a
            :href="`${$t('banners.envirosense.cta.url')}?calculationId=${formState.results.calculation_id}`"
            target="_blank"
            class="btn btn-dark"
          >
            {{ $t('banners.envirosense.cta.label') }}
          </a>
        </div>
      </div>
      <div class="image-slider" :style="`--width: ${width}%`">
        <div class="original">
          <img src="@/assets/images/orto/2.png" alt="orto" />
        </div>
        <div class="overlay">
          <img src="@/assets/images/orto/1.png" alt="orto" />
        </div>
        <div class="slider">
          <input type="range" min="0" max="100" v-model="width" />
          <span></span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from 'vue'
import useFormState from '@/hooks/formState'

export default defineComponent({
  name: 'OrtoBanner',
  data() {
    return {
      width: '75'
    }
  },
  setup() {
    const { formState } = useFormState()

    return {
      formState
    }
  }
})
</script>

<style scoped lang="scss">
.envirosense-banner {
  color: #fff;
  background: linear-gradient(206.38deg, #fdab1c 0%, #fb9f1e 23.8%, #e42730 206%);

  @media (max-width: 767px) {
    margin-top: 65px;
  }

  @media (min-width: 768px) {
    margin: 75px 0;
  }
}

.banner__content {
  display: flex;
  padding: 20px;
  width: 100%;

  @media (max-width: 767px) {
    justify-content: center;
    flex-direction: column-reverse;
    gap: 30px;
    align-items: center;
  }

  @media (min-width: 768px) {
    justify-content: space-between;
  }
}

.banner__text {
  margin-bottom: 25px;
}

.image-slider {
  position: relative;
  width: 200px;
  height: 200px;
  margin: 30px;

  &:before {
    position: absolute;
    display: block;
    top: -20px;
    left: -20px;
    width: 240px;
    height: 240px;
    background:
      linear-gradient(to right, #fff 2px, transparent 2px) 0 0,
      linear-gradient(to right, #fff 2px, transparent 2px) 0 100%,
      linear-gradient(to left, #fff 2px, transparent 2px) 100% 0,
      linear-gradient(to left, #fff 2px, transparent 2px) 100% 100%,
      linear-gradient(to bottom, #fff 2px, transparent 2px) 0 0,
      linear-gradient(to bottom, #fff 2px, transparent 2px) 100% 0,
      linear-gradient(to top, #fff 2px, transparent 2px) 0 100%,
      linear-gradient(to top, #fff 2px, transparent 2px) 100% 100%;
    background-repeat: no-repeat;
    background-size: 20px 20px;
    content: '';
  }

  img {
    height: 100%;
    object-fit: cover;
  }
}

.overlay {
  position: relative;
  display: inline-block;
  width: var(--width);
  height: 100%;
  overflow: hidden;

  img {
    max-width: initial;
    height: 100%;
  }
}

.original {
  position: absolute;
  width: 100%;
  height: 100%;

  img {
    max-width: 100%;
  }
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;

  &:after {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    left: calc(var(--width) - 21px);
    width: 47px;
    height: 47px;
    background: #444;
    border-radius: 1000px;
    transform: translate(0, -50%);
    pointer-events: none;
    content: '< >';
    z-index: 100;
  }

  input {
    width: calc(100% + 16px);
    margin: 0 -8px;
    background: transparent;
    border: 0;
    outline: 0;
    z-index: 100;
    appearance: none;
    cursor: pointer;

    ~ span {
      position: absolute;
      display: block;
      left: var(--width);
      width: 4px;
      height: 100%;
      background: #444;
      border-radius: 1000px;
    }
  }
}
</style>
