<template>
  <div class="form-wizard-steps">
    <div class="container">
      <ul class="steps">
        <li
          @click="currentStep > index && goToStep(index)"
          class="step"
          v-for="(step, index) in steps"
          :key="step"
          :class="{ current: currentStep === index, active: currentStep > index }"
          data-test="step"
        >
          <span class="step-number">{{ index + 1 }}</span>
          <span class="step-label" data-test="step-label">
            {{ t(`steps.${step[0].toLowerCase() + step.substring(1)}.label`) }}
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

import useFormWizard from '@/hooks/formWizard'

export default defineComponent({
  name: 'WizardSteps',
  props: {
    steps: {
      type: Array,
      required: true,
      default: () => []
    },
    currentStep: {
      type: Number,
      required: true,
      default: 0
    },
    stepLabels: {
      type: Array
    }
  },
  setup() {
    const { goToStep } = useFormWizard()
    const { t } = useI18n()

    return {
      goToStep,
      t
    }
  }
})
</script>

<style lang="scss" scoped>
.form-wizard-steps {
  background-color: #ededed;
}

.steps {
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 28px 0;
}

.step-number {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  font-size: 12px;
  color: #bbbbbb;
  background-color: #ffffff;
  letter-spacing: 0;
  border-radius: 50%;
}

.step {
  display: flex;
  align-items: center;
  font-weight: 600;

  &:not(:last-child) {
    margin-right: 50px;

    @media (max-width: 576px) {
      margin-right: 15px;
    }
  }

  &.current,
  &.active {
    .step-number {
      color: #fff;
      background-color: #fca41e;
    }
  }

  &.active {
    cursor: pointer;
  }
}

.step-label {
  color: #333333;
  margin-left: 10px;
  font-size: 12px;

  @media (max-width: 992px) {
    display: none;
  }
}
</style>
