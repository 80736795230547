<template>
  <div class="form-component">
    <h2>{{ t('steps.roofType.degree.title') }}</h2>
    <div class="degree d-flex flex-column flex-lg-row justify-content-center">
      <div class="example-roof-image order-lg-1">
        <img :src="roofDegreeImageSrc" alt="roof-type" />
        <div class="form-group degree-field">
          <label for="degree">{{ t('fields.degree') }}</label>
          <input
            v-model.number="validations.degree.$model"
            type="number"
            name="degree"
            id="degree"
            class="form-control"
            :class="{ 'is-invalid': validations.degree.$error }"
            :placeholder="DEFAULT_ROOF_DEGREE"
            :min="DEFAULT_ROOF_DEGREE"
            max="45"
            @input="onInput"
          />
          <ErrorMessage :field="validations.degree" />
        </div>
      </div>
      <div class="d-flex align-items-baseline">
        <div class="degree-chart" @mousedown="down($event)" @mouseup="up($event)">
          <div class="degree-control" :style="styleClass">
            <div class="degree-numbers-display">
              <span :style="degreeStyleClass">{{ degree }}°</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required, minValue, maxValue, alphaNum } from '@vuelidate/validators'
import { useI18n } from 'vue-i18n'

import { LANGUAGE_CONFIG } from '@/services/language'

import ErrorMessage from '@/components/Global/ErrorMessage'

export default defineComponent({
  name: 'DegreeSelect',
  emits: ['on-select'],
  components: {
    ErrorMessage
  },
  props: {
    current: {
      type: Number,
      required: true
    }
  },
  setup(props, { emit }) {
    const { t } = useI18n()
    const currentLanguage = localStorage.getItem('language') || process.env.VUE_APP_DEFAULT_LOCALE
    const DEFAULT_ROOF_DEGREE = LANGUAGE_CONFIG[currentLanguage].minDegree

    const degree = ref(props.current)

    const rules = {
      degree: {
        required,
        alphaNum,
        minValue: minValue(DEFAULT_ROOF_DEGREE),
        maxValue: maxValue(45)
      }
    }

    const validations = useVuelidate(rules, { degree })

    const zeroPosition = {
      x: null,
      y: null
    }

    const updateDegree = event => {
      let x = event.pageX
      if (x < zeroPosition.x) {
        degree.value = 45
        return
      }

      let y = event.pageY
      if (y > zeroPosition.y) {
        degree.value = LANGUAGE_CONFIG[currentLanguage].minDegree
        return
      }

      let opposite = zeroPosition.y - y

      let adjacent = x - zeroPosition.x

      if (opposite > adjacent) opposite = adjacent

      if (opposite < adjacent / 5.6725) opposite = adjacent / 5.6725

      const rad = Math.atan(adjacent > 0 ? opposite / adjacent : LANGUAGE_CONFIG[currentLanguage].minDegree)

      degree.value = Math.round(rad * (180 / Math.PI))

      if (degree.value > 45) {
        degree.value = 45
      } else if (degree.value < LANGUAGE_CONFIG[currentLanguage].minDegree) {
        degree.value = LANGUAGE_CONFIG[currentLanguage].minDegree
      }
    }

    const up = () => {
      document.removeEventListener('mousemove', updateDegree)
      document.removeEventListener('mouseup', up)
      emit('on-select', degree.value)
    }

    const down = event => {
      event.preventDefault()

      zeroPosition.x = event.target.offsetLeft
      zeroPosition.y = event.target.offsetTop + event.target.offsetHeight

      updateDegree(event)

      document.addEventListener('mousemove', updateDegree)
      document.addEventListener('mouseup', up)
    }

    let inputTimeout
    const onInput = event => {
      inputTimeout = setTimeout(() => {
        if (!degree.value || degree.value < DEFAULT_ROOF_DEGREE) {
          degree.value = DEFAULT_ROOF_DEGREE
        }

        if (degree.value > 45) {
          degree.value = 45
          event.target.select()
        }

        emit('on-select', degree.value)

        clearTimeout(inputTimeout)
        inputTimeout = null
      }, 400)
    }

    const styleClass = computed(() => {
      let deg = degree.value
      if (deg > 45) {
        deg = 45
      }

      if (deg < DEFAULT_ROOF_DEGREE) {
        deg = 15
      }
      return {
        transform: `rotate(${-deg}deg)`
      }
    })

    const degreeStyleClass = computed(() => {
      let deg = degree.value
      if (deg > 45) {
        deg = 45
      }

      if (deg < DEFAULT_ROOF_DEGREE) {
        deg = 15
      }
      return {
        transform: `rotate(${deg}deg)`
      }
    })

    const roofDegreeImageSrc = computed(() => {
      if (degree.value >= 15 && degree.value <= 45) {
        return require(`@/assets/images/roof-degrees/${Math.ceil(degree.value / 5) * 5}.png`)
      } else {
        return require(`@/assets/images/roof-degrees/15.png`)
      }
    })

    return {
      up,
      down,
      styleClass,
      degree,
      roofDegreeImageSrc,
      degreeStyleClass,
      validations,
      t,
      DEFAULT_ROOF_DEGREE,
      onInput
    }
  }
})
</script>

<style lang="scss" scoped>
@import '@/assets/style/base/_variables.scss';

h2 {
  margin-top: 50px;
}

.degree-field {
  margin: 0 auto;

  @media (min-width: 765px) {
    max-width: 185px;
  }
}

.degree-chart {
  width: 260px;
  height: 185px;
  position: relative;
  background: bottom url('~@/assets/images/roof-degrees/degree.png') no-repeat;
  background-size: cover;
  margin-top: 80px;
}

.degree-control {
  position: absolute;
  width: 100%;
  height: 5px;
  background: black;
  bottom: 0;
  left: 0;
  transform-origin: left center;
  pointer-events: none;

  .degree-number {
    position: absolute;
    right: -30px;
    top: 50%;
    font-family: $font-family-heading-extrabold;
    transform: translate(50%, -50%);
  }

  &:before {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    background: inherit;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%);
  }

  &:after {
    margin-right: 5px;
    content: '';
    background: #fdab1c;
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(50%, -50%);
    border: 2px solid white;
  }
}

.degree-numbers-display {
  position: relative;
  font-weight: bold;

  span {
    position: absolute;
    right: -44px;
    font-size: 14px;
  }
}

.example-roof-image {
  font-family: $font-family-heading-extrabold;

  @media screen and (max-width: 991px) {
    font-size: 18px;
  }

  @media screen and (min-width: 992px) {
    margin-left: 100px;
    font-size: 30px;
  }

  img {
    max-width: 240px;
  }
}
</style>
