<template>
  <div class="form-component">
    <div class="section-title text-center">
      <h2>{{ t('steps.measurements.title') }}</h2>
      <p>{{ t('steps.measurements.description') }}</p>
    </div>

    <div class="roof-sides">
      <div class="row">
        <div class="col-md-6">
          <img :src="roofType.image" :alt="roofType.title" />
        </div>
        <div class="col-md-4">
          <MeasurementsFields
            v-if="roofType && roofType.sides"
            :roof-type="roofType"
            :current="current"
            :server-validation="serverValidation"
          />
          <SkeletonLoader v-else :items="2" :items-per-row="1" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

import SkeletonLoader from '@/components/Global/SkeletonLoader'
import MeasurementsFields from '@/components/Calculator/MeasurementsFields'

export default defineComponent({
  name: 'MeasurementsForm',
  emits: ['on-input'],
  components: {
    SkeletonLoader,
    MeasurementsFields
  },
  props: {
    roofType: {
      type: Object,
      default: () => ({})
    },
    serverValidation: {
      type: Object,
      default: () => ({})
    },
    current: {
      type: Object,
      required: true
    }
  },
  setup() {
    const { t } = useI18n()

    return {
      t
    }
  }
})
</script>

<style lang="scss" scoped>
h2 {
  @media screen and (max-width: 991px) {
    margin-bottom: 5px;
    margin-top: 60px;
  }

  @media screen and (min-width: 992px) {
    margin-bottom: 8px;
    margin-top: 80px;
  }
}

.roof-sides {
  :deep(.skeleton-loader) {
    margin-top: 40px;
  }

  :deep(.skeleton) {
    height: 38px;
    margin: 24px 15px 1rem;
    border: 1px solid #ced4da;
    position: relative;
    padding: 0 15px;

    &:before {
      content: '';
      display: block;
      height: 12px;
      background: #333;
      opacity: 0.5;
      width: 50%;
      position: absolute;
      top: -20px;
      left: 0;
    }

    img {
      height: 18px;
    }
  }
}
</style>
